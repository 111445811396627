import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';
import { useSetRecoilState } from 'recoil';

import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HistoryState, SURGERIES_PAGE_NAME } from '~types';

const BUTTON_WIDTH = 300;

export const SurgeriesPage: React.FC = () => {
  const { t } = useTranslation();
  const setHistoryState = useSetRecoilState(historyAtom);
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const { dispatch } = useRouteNavigation<typeof SURGERIES_PAGE_NAME>();

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState> | null) => {
      if (!formValues) {
        setHistoryState(prevState => ({ ...prevState, surgeries: undefined }));
      }

      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'surgeries', 'surgeries', t('surgeries.skipTitle'));
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, setHistoryState, saveHistory, t, dispatch]
  );

  return (
    <JotaiForm onSubmit={handleSubmit} atom={historyAtom} isLoading={isLoading}>
      <Container
        id="surgeries"
        title={t('surgeries.title')}
        footer={
          <FormFooter
            submitButtonTitle={t('continue')}
            secondaryButtonTitle={t('surgeries.skipTitle')}
            onSecondaryPress={() => handleSubmit(null)}
            requiredFields={['surgeries']}
            submitButtonProps={{ style: { width: BUTTON_WIDTH } }}
            secondaryButtonProps={{ style: { width: BUTTON_WIDTH } }}
          />
        }
      >
        <ConnectedTextInput name="surgeries" multiline placeholder={t('surgeries.placeholder')} testID="Surgeries" />
      </Container>
    </JotaiForm>
  );
};
