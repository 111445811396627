import { useCallback } from 'react';

import { useFocusEffect } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { waitlistAtom } from '~modules/state';

/**
 * Stores in recoil state if the user is on the scheduling page.
 * This is so we don't have to rely on the filename ./src/app/scheduling.tsx
 * never changing, and can still reliable know if we're on the scheduling
 * page inside the waitlist modal
 */
export const useWaitlistOnSchedulingPage = () => {
  const setWaitlistState = useSetRecoilState(waitlistAtom);

  useFocusEffect(
    useCallback(() => {
      setWaitlistState(prevState => ({ ...prevState, isOnSchedulingPage: true }));

      return () => {
        setWaitlistState(prevState => ({ ...prevState, isOnSchedulingPage: false }));
      };
    }, [setWaitlistState])
  );
};
