import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { useWaitlistModalState } from '~modules/state';

import { waitlistFooterThemedStyles } from './styles';

export const WaitlistFooter = () => {
  const [state, dispatch] = useWaitlistModalState();
  const { t } = useTranslation();
  const [styles] = useTheme(waitlistFooterThemedStyles);
  const { isMobile } = useBrowserTypeMap();

  if (state.isComplete) {
    return null;
  }

  const onPress = () => {
    dispatch({ type: 'open' });
  };

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <Text size="m" style={styles.title}>
        {t('scheduling.waitlistTitle')}
      </Text>
      <Button
        type="secondary"
        size="s"
        onPress={onPress}
        fixedWidth={false}
        style={styles.button}
        testID="Scheduling_JoinWaitlist"
        fontStyle="book"
      >
        {t('scheduling.waitlistButton')}
      </Button>
    </View>
  );
};
