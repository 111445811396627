import './base.css';
import '@almond/ui/index.css';

import React, { useEffect, useState } from 'react';

import { initAnalytics, useInjectGTM, useInjectShareASale } from '@almond/analytics';
import { BrowserTypeLogger, ScrollStateProvider, ToastNotificationProvider, useInjectCssVariables } from '@almond/ui';
import { cypressUtilities, initSentry, StatsigProvider } from '@almond/utils';
import * as SplashScreen from 'expo-splash-screen';
import { RecoilRoot } from 'recoil';
import { v4 } from 'uuid';

import { fontsUtilities } from '~assets';
import env from '~env';
import { WaitlistModal } from '~modules/appointment';
import { Auth0Provider, AuthStateProvider } from '~modules/auth';
import { ErrorBoundary } from '~modules/errors';
import { IdleModalWrapper } from '~modules/integration';
import { ApplicationStack } from '~modules/navigation';
import { CheckPromotionCodes, Elements } from '~modules/payment';
import { VISIT_REASONS_PAGE_NAME } from '~types';

export const unstable_settings = {
  initialRouteName: VISIT_REASONS_PAGE_NAME,
};

// Prevent the splash screen from auto-hiding before asset loading is complete.
SplashScreen.preventAutoHideAsync();

// Enabling Sentry and Analytics only in builds.
if (process.env.NODE_ENV === 'production') {
  initSentry(process.env.EXPO_PUBLIC_ENV);
  initAnalytics(process.env.EXPO_PUBLIC_ENV);
}

const RootLayoutContent = () => {
  useInjectGTM(env.GTM_KEY);
  useInjectShareASale();
  useInjectCssVariables();

  return (
    <RecoilRoot>
      <ScrollStateProvider>
        <ToastNotificationProvider getUuid={v4}>
          <StatsigProvider
            sdkKey={env.STATSIG_CLIENT_ID}
            environment={env.STATSIG_ENVIRONMENT}
            isTest={cypressUtilities.isCypressRunning()}
          >
            <ErrorBoundary>
              <Elements>
                <Auth0Provider>
                  <BrowserTypeLogger />
                  <AuthStateProvider>
                    <CheckPromotionCodes />
                    <ApplicationStack />
                    <IdleModalWrapper />
                    <WaitlistModal />
                  </AuthStateProvider>
                </Auth0Provider>
              </Elements>
            </ErrorBoundary>
          </StatsigProvider>
        </ToastNotificationProvider>
      </ScrollStateProvider>
    </RecoilRoot>
  );
};

const RootLayout: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<any>();

  // Expo Router uses Error Boundaries to catch errors in the navigation tree.
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        await SplashScreen.preventAutoHideAsync();
        await fontsUtilities.loadFonts();
        setIsInitialized(true);
      } catch (e) {
        setError(e);
      }
    };

    initialize();
  }, [error]);

  useEffect(() => {
    if (isInitialized) {
      SplashScreen.hideAsync();
    }
  }, [isInitialized]);

  if (!isInitialized) {
    return null;
  }

  return <RootLayoutContent />;
};

export default RootLayout;
