import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import { waitlistAtom } from './atoms';

import type { WaitlistState } from '~types';

export type WaitlistAction = { type: 'open' } | { type: 'close' } | { type: 'complete' };

const waitlistReducer = (state: WaitlistState, action: WaitlistAction): WaitlistState => {
  switch (action.type) {
    case 'open':
      return { ...state, isVisible: true };
    case 'close':
      return { ...state, isVisible: false };
    case 'complete':
      return { ...state, isComplete: true };
    default:
      throw new Error('Invalid action');
  }
};

export const useWaitlistModalState = () => {
  const [waitlistState, setWaitlistState] = useRecoilState(waitlistAtom);

  const dispatch = useCallback(
    (action: WaitlistAction) => {
      setWaitlistState(prevState => waitlistReducer(prevState, action));
    },
    [setWaitlistState]
  );

  return [waitlistState, dispatch] as const;
};
