import React, { useCallback, useEffect } from 'react';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, useTheme } from '@almond/ui';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ErrorMessage } from '~modules/errors';
import { ContainerWithSidebar } from '~modules/layout';
import { useCalculatePrice } from '~modules/payment';
import { isBaselineProduct, isVirtualMembershipProduct, useStripeProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom, creditCardAtom, patientProgressAtom, profileProgressAtom } from '~modules/state';
import { NotificationStatusBar } from '~modules/ui';

import { useDetermineFirstMonthFree } from '../../hooks';
import { Baseline } from './Baseline';
import { Membership } from './Membership';
import { PricingExplanationSubtitle } from './PricingExplanationSubtitle';
import { PricingExplanationTitle } from './PricingExplanationTitle';
import { SidebarContent } from './SidebarContent';

import themedStyles from './styles';

import type { PRICING_EXPLANATION_PAGE_NAME } from '~types';

// eslint-disable-next-line max-statements
export const PricingExplanationPage: React.FC = () => {
  const { dispatch } = useRouteNavigation<typeof PRICING_EXPLANATION_PAGE_NAME>();
  const { doAsync, isLoading } = useAsync();
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { subscriptionActive } = useRecoilValue(patientProgressAtom);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const isResubscribe = !subscriptionActive && !isNewMemberRemote;
  const setCreditCardState = useSetRecoilState(creditCardAtom);
  const { product } = useStripeProduct();
  const priceOutput = useCalculatePrice(product);
  const isBaseline = isBaselineProduct(product);
  const isVirtualMembership = isVirtualMembershipProduct(product);
  const variant = (() => {
    if (isBaseline) return 'baseline';
    if (isVirtualMembership) return 'virtualMembership';

    return 'gynecologyMembership';
  })();
  const setProfileProgressState = useSetRecoilState(profileProgressAtom);

  useEffect(() => {
    if (isResubscribe) {
      setCreditCardState(prev => ({ ...prev, promotionCode: undefined }));
    }
  }, [isResubscribe, setCreditCardState]);

  // If a user accidentally clears FIRSTMONTHFREE code or clicks a link with an invalid code,
  // they can go back to the PricingExplanation page and the code will be reapplied
  useDetermineFirstMonthFree(isResubscribe);

  useEffect(() => {
    setProfileProgressState(prevState => ({ ...prevState, isPricingPageViewed: true }));
  }, [setProfileProgressState]);

  const handleSubmit = useCallback(() => {
    const toCall = () => dispatch('submit');

    doAsync(toCall);
  }, [doAsync, dispatch]);

  const content = (() => {
    if (variant === 'baseline') {
      return <Baseline onSubmitPress={handleSubmit} isLoading={isLoading} />;
    }

    return (
      <Membership onSubmitPress={handleSubmit} isLoading={isLoading} variant={variant} priceOutput={priceOutput} />
    );
  })();

  if (priceOutput.isLoadingPrice) {
    return <ActivityIndicator />;
  }

  if (priceOutput.priceError) {
    return (
      <ErrorMessage
        error={priceOutput.priceError}
        onTryAgain={() => priceOutput.retryPrice()}
        isFull={false}
        style={styles.errorMessage}
      />
    );
  }

  return (
    <ContainerWithSidebar
      id="pricingExplanation"
      statusBar={
        isResubscribe && <NotificationStatusBar type="info" content={t(`pricingExplanation.resubscribeBanner`)} />
      }
      content={content}
      sidebarContent={!isBaseline && <SidebarContent />}
      title={<PricingExplanationTitle variant={variant} priceOutput={priceOutput} />}
      subtitle={<PricingExplanationSubtitle variant={variant} />}
    />
  );
};
