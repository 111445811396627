import React from 'react';

import { useTranslation } from '@almond/localization';

import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useRouteNavigation } from '~modules/routing';

import { VisitCostMessage } from '../../components';

import type { INSURANCE_NOT_ACCEPTED_PAGE_NAME } from '~types';

export const InsuranceNotAcceptedPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof INSURANCE_NOT_ACCEPTED_PAGE_NAME>();

  return (
    <JotaiForm onSubmit={() => dispatch('submit')}>
      <Container
        id="insuranceNotAccepted"
        title={t('insuranceNotAccepted.title')}
        footer={<FormFooter submitButtonTitle={t('continue')} />}
      >
        <VisitCostMessage />
      </Container>
    </JotaiForm>
  );
};
