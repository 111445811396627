import { useCallback } from 'react';

import { setHeapUser } from '@almond/analytics';
import * as Sentry from '@sentry/browser';
import { useGlobalSearchParams } from 'expo-router';
import Cookies from 'js-cookie';
import { useSetRecoilState } from 'recoil';
import { useUpdateUser } from 'statsig-react';

import env from '~env';
import { appointmentsApi, usersApi } from '~modules/api';
import { appointmentParamsAtom, userAtom } from '~modules/state';

import { CDN_COOKIE_NAME } from '../config';
import { log } from '../logger';
import { userUtilities } from '../services';

const setCookie = (cdnCookie: string) => {
  Cookies.remove(CDN_COOKIE_NAME);
  Cookies.set(CDN_COOKIE_NAME, cdnCookie, {
    domain: env.APEX_DOMAIN,
    sameSite: 'None',
    secure: true,
    expires: 1,
  });
};

const useSetUser = () => {
  const setUserState = useSetRecoilState(userAtom);
  const updateStatsigUser = useUpdateUser();
  const setAppointmentParamsState = useSetRecoilState(appointmentParamsAtom);
  const searchParams = useGlobalSearchParams();

  return useCallback(async () => {
    const { patientUuid, ...user } = await usersApi.getUser();

    log('User authenticated:', user);

    const userMetadata = {
      patientUuid,
      role: patientUuid ? 'patient' : 'internal',
    } as const;

    const isAdmin = userUtilities.isAdmin(user);

    setUserState(prevState => ({ ...prevState, user, isAdmin }));
    Sentry.setUser({ id: user.authId });
    setHeapUser(user.authId, userMetadata);
    setCookie(user.cdnCookie);
    updateStatsigUser({
      userID: user.authId,
      custom: userMetadata,
    });

    // TODO move everything below this to useLoadPatient()

    const uuid = patientUuid || searchParams.patient_uuid;

    // Once we get /users/me (for the patient), or have logged on as a provider, get the patient's
    // past appointment count. NOTE that `uuid` will be falsy for the new patient self-book flow,
    // since the user auths with Auth0, comes back to the application, and calls /users/me BEFORE
    // the "create patient" API call runs. So the patient can call /users/me BUT `patient_uuid` will
    // be undefined.
    // However, we can guarantee that in that case, `getPastAppointmentCount` will return 0 for that user,
    // so the network request is unnecessary anyway!
    let isNewMemberRemote = true;

    if (uuid) {
      const response = await appointmentsApi.getPastAppointmentCount(uuid);

      isNewMemberRemote = response.count === 0;
    }

    setAppointmentParamsState(prevState => ({
      ...prevState,
      isNewMemberRemote,
    }));

    return uuid;
  }, [searchParams.patient_uuid, setAppointmentParamsState, setUserState, updateStatsigUser]);
};

export default useSetUser;
