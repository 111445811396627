import { type RefObject, useEffect } from 'react';

import dayjs from 'dayjs';

import type { Dayjs } from 'dayjs';
import type { FlatList, View } from 'react-native';

/**
 * When tabbing through the document, treat the calendar bar as a single tabbable
 * element. When focused, use the arrow keys to select a new date. The tab key moves
 * on to the text section of the page instead of the next date.
 */
export const useKeyboardNavigation = (
  scrollContainerRef: RefObject<FlatList<Dayjs>>,
  scrollLeftButtonRef: RefObject<View>,
  scrollRightButtonRef: RefObject<View>
) => {
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current?.getNativeScrollRef();

    if (!scrollContainer || !(scrollContainer instanceof HTMLDivElement)) {
      return;
    }

    let isFocused = false;

    scrollContainer.setAttribute('tabindex', '0');

    const onFocusin = (e: FocusEvent) => {
      scrollContainer.setAttribute('tabindex', '-1');

      if (e.target !== scrollContainer) {
        return;
      }

      const currentlySelectedElement = scrollContainer.querySelector('[aria-current="true"]');

      if (currentlySelectedElement instanceof HTMLElement) {
        currentlySelectedElement.focus();
      }

      isFocused = true;
    };
    const onFocusout = () => {
      // Only set isFocused to false if the focus is not within the scroll container
      // This is to prevent the focus from being lost when one day is unfocused when
      // a sibling date is focused.
      isFocused = scrollContainer.matches(':focus-within');
      if (!isFocused) {
        scrollContainer.setAttribute('tabindex', '0');
      }
    };
    const onKeydown = (event: KeyboardEvent) => {
      if (!isFocused) {
        return;
      }

      if (event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
        return;
      }

      const currentlySelectedElement = document.activeElement;
      const date = currentlySelectedElement?.getAttribute('data-date');
      const nextDate = date
        ? dayjs(date)
            .add(event.key === 'ArrowRight' ? 1 : -1, 'day')
            .format('YYYY-MM-DD')
        : null;
      const nextElement = scrollContainer.querySelector(`[data-date="${nextDate}"]`);

      if (nextElement instanceof HTMLElement) {
        nextElement.focus();
      }
    };

    scrollContainer.addEventListener('focusin', onFocusin);
    scrollContainer.addEventListener('focusout', onFocusout);
    document.addEventListener('keydown', onKeydown);

    return () => {
      scrollContainer.removeEventListener('focusin', onFocusin);
      scrollContainer.removeEventListener('focusout', onFocusout);
      document.removeEventListener('keydown', onKeydown);
    };
  }, [scrollContainerRef, scrollLeftButtonRef, scrollRightButtonRef]);
};
