import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedSelectInput } from '@almond/ui';
import { usePathname } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { profilesApi } from '~modules/api';
import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { insuranceAtom, profileAtom } from '~modules/state';

import { INSURANCE_OPTIONS } from '../../config';
import { logAndCapture } from '../../logger';

import type { FormValues } from '@almond/ui';
import type { INSURANCE_PAGE_NAME, InsuranceFormValues } from '~types';

export const InsurancePage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof INSURANCE_PAGE_NAME>();
  const profileState = useRecoilValue(profileAtom);
  const pathname = usePathname();

  const handleSubmit = useCallback(
    (values: FormValues<InsuranceFormValues> | null) => {
      const toCall = async (): Promise<void> => {
        if (!profileState.uuid) {
          return logAndCapture("profile uuid is not specified, so can't create the insurance.");
        }

        const response = await profilesApi.createInsurance(profileState.uuid)({
          carrier: values ? values.carrier.value.value : 'noInsurance',
          optionalCarrierName: values ? values.carrier.key : undefined,
        });

        await dispatch('submit', {
          hasInsurance: !!values,
          isAccepted: response.isAccepted,
        });
      };

      doAsync(toCall);
    },
    [doAsync, profileState.uuid, dispatch]
  );

  return (
    <JotaiForm atom={insuranceAtom} onSubmit={handleSubmit} isLoading={isLoading}>
      <Container
        id="insurance"
        title={t('insurance.title')}
        size="M"
        footer={
          <FormFooter
            submitButtonTitle={t('continue')}
            secondaryButtonTitle={t('insurance.noInsurance')}
            onSecondaryPress={() => handleSubmit(null)}
            requiredFields={['carrier']}
          />
        }
      >
        <ConnectedSelectInput
          name="carrier"
          options={INSURANCE_OPTIONS}
          keyTitle={t('insurance.addCarrierName')}
          testID="Insurance"
          keyTestID="Other"
          withTitle
          closeModalTrigger={pathname}
        />
      </Container>
    </JotaiForm>
  );
};
