import React from 'react';

import { ActivityIndicator, BaseButton, Text, useTheme } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';

import { unstable_styles as cssStyles } from './ModalButton.module.css';

import { themedStyles } from './styles';

import type { BaseButtonProps } from '@almond/ui';

export type ModalButtonProps = Omit<BaseButtonProps, 'children'> & { children?: React.ReactNode; isLoading?: boolean };

export const ModalButton = (props: ModalButtonProps) => {
  const { children, style, isLoading, ...restProps } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <BaseButton {...restProps} style={combineUnstableStyles(styles.button, cssStyles.button, style)}>
      <Text style={cssStyles.text} size="l">
        {children}
      </Text>

      {isLoading && <ActivityIndicator size={25} lineWidth={3} style={styles.loading} />}
    </BaseButton>
  );
};
