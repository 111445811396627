.button {
  opacity: 1 !important;
  background-color: var(--color-background);
}
.button:active {
  background-color: var(--color-accent);
}
.button:focus-visible {
  outline: 3px solid var(--color-accent);
}

.text {
  color: var(--color-text);
}

.button:active .text {
  color: var(--color-light-text);
}
