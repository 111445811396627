import React, { useMemo } from 'react';

import { colors, useTheme } from '@almond/ui';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import env from '~env';
import { ErrorMessage } from '~modules/errors';
import { useStripeProduct } from '~modules/product';

import { useCalculatePrice } from '../../hooks';

import type { StripeElementsOptions } from '@stripe/stripe-js';

const stripePromise = loadStripe(env.STRIPE_API_KEY);
const inputStyles = {
  border: `0.5px solid ${colors.FIG['04']}`,
  boxShadow: '0px 4px 4px 0px rgba(69, 69, 69, 0.12)',
};

export const Elements = (props: React.PropsWithChildren) => {
  const { children } = props;
  const [theme] = useTheme();
  const { product, productError, retryProduct, isLoadingProduct } = useStripeProduct();
  const { discountPrice, priceError, retryPrice } = useCalculatePrice(product);

  const mode = useMemo(() => {
    if (!product) return 'subscription';

    if (discountPrice === 0) return 'setup';

    return product.price.type === 'one_time' ? 'payment' : 'subscription';
  }, [product, discountPrice]);

  const options: StripeElementsOptions = useMemo(
    () =>
      ({
        mode,
        amount: discountPrice,
        currency: 'usd',
        paymentMethodCreation: 'manual',
        appearance: {
          theme: 'flat',
          variables: {
            colorPrimary: theme.primary,
            colorBackground: theme.background,
            colorText: theme.text,
            colorSuccess: theme.info,
            colorDanger: theme.error,
            colorWarning: theme.lightError,
            focusBoxShadow: 'none',
            focusOutline: '2px solid var(--colorPrimary)',
          },
          labels: 'floating',
          rules: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.Input': inputStyles,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.Tab': inputStyles,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '.TermsText': {
              // Hiding default TermsText to display a custom one
              fontSize: '0',
            },
          },
        },
        paymentMethodTypes: ['card'],
      }) as const,
    [discountPrice, mode, theme.background, theme.error, theme.info, theme.lightError, theme.primary, theme.text]
  );

  if (!isLoadingProduct && (productError || !product)) {
    return <ErrorMessage error={productError} onTryAgain={retryProduct} />;
  }

  if (priceError) {
    return <ErrorMessage error={priceError} onTryAgain={retryPrice} />;
  }

  return (
    <StripeElements stripe={stripePromise} options={options}>
      {children}
    </StripeElements>
  );
};
