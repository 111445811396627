import { useEffect } from 'react';

import { isBaselineProduct, useStripeProduct } from '~modules/product';

import { useCheckCode, useCheckPromotionCode } from '../../hooks';

export const CheckPromotionCodes = () => {
  useCheckPromotionCode('promotionCode', 'stripe_discount');
  useCheckPromotionCode('friendReferralCode', 'friend_referral');

  const { product } = useStripeProduct();
  const isBaseline = isBaselineProduct(product);
  const { checkCode } = useCheckCode();

  useEffect(() => {
    if (isBaseline) {
      checkCode('BASELINEPROMO');
    }
  }, [checkCode, isBaseline]);

  return null;
};
