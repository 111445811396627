import React from 'react';

import { ScrollableModal, useTheme } from '@almond/ui';

import { ErrorBoundary } from '~modules/errors';
import { useWaitlistModalState } from '~modules/state';

import { WaitlistModalComplete } from './WaitlistModalComplete';
import { WaitlistModalForm } from './WaitlistModalForm';

import { themedStyles } from './styles';

export const WaitlistModal = () => {
  const [state, dispatch] = useWaitlistModalState();

  const [styles] = useTheme(themedStyles);

  return (
    <ScrollableModal
      isVisible={state.isVisible}
      onRequestClose={() => dispatch({ type: 'close' })}
      containerStyle={styles.container}
      contentContainerStyle={styles.contentContainer}
      showClose
      testID="WaitlistModal"
    >
      <ErrorBoundary>
        {state.isComplete ? (
          <WaitlistModalComplete onClose={() => dispatch({ type: 'close' })} />
        ) : (
          <WaitlistModalForm onComplete={() => dispatch({ type: 'complete' })} />
        )}
      </ErrorBoundary>
    </ScrollableModal>
  );
};
