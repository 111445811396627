import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { FormFooter, ImageInput, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { insuranceAtom, userAtom } from '~modules/state';

import { useAddInsuranceId, useUploadInsuranceCard } from '../../hooks';
import { logAndCapture } from '../../logger';
import { validationSchema } from './validations';

import type { FormValues } from '@almond/ui';
import type { INSURANCE_ID_PAGE_NAME, InsuranceIdFormValues, InsuranceIdParams } from '~types';

export const InsuranceIdPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const uploadInsuranceCard = useUploadInsuranceCard();
  const addInsuranceId = useAddInsuranceId();
  const userState = useRecoilValue(userAtom);
  const { dispatch } = useRouteNavigation<typeof INSURANCE_ID_PAGE_NAME>();
  const searchParams = useLocalSearchParams<InsuranceIdParams>();

  const handleSubmit = useCallback(
    (values: FormValues<InsuranceIdFormValues>) => {
      const toCall = async (): Promise<void> => {
        if (!userState.user) return logAndCapture("The current user doesn't exist, so can't submit the insurance id.");

        await uploadInsuranceCard(values);
        await addInsuranceId(values);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [addInsuranceId, dispatch, doAsync, uploadInsuranceCard, userState.user]
  );

  return (
    <JotaiForm atom={insuranceAtom} onSubmit={handleSubmit} validationSchema={validationSchema} isLoading={isLoading}>
      <Container
        id="insuranceId"
        title={t('insuranceId.title')}
        size="M"
        shouldHideCloseButton={searchParams.should_hide_close_button === 'true'}
        footer={
          <FormFooter
            submitButtonTitle={t('continue')}
            secondaryButtonTitle={searchParams.can_skip === 'true' ? t('insuranceId.secondaryTitle') : undefined}
            onSecondaryPress={searchParams.can_skip === 'true' ? () => dispatch('submit') : undefined}
            requiredFields={['card.front']}
          />
        }
      >
        <ConnectedTextInput name="carrier.value.label" readOnly />
        <ConnectedTextInput
          name="id"
          placeholder={t('insuranceId.label')}
          label={t('insuranceId.label')}
          testID="InsuranceId"
        />
        <ImageInput name="card.front" placeholder={t('insuranceId.card.front')} iconTestID="FrontCard" />
        <ImageInput name="card.back" placeholder={t('insuranceId.card.back')} iconTestID="BackCard" />
      </Container>
    </JotaiForm>
  );
};
