import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Accordion, ActivityIndicator, MaterialIcon, sizes, Text, useBrowserType, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';

import { ErrorMessage } from '~modules/errors';
import { ContainerWithSidebar } from '~modules/layout';
import { useCalculatePrice } from '~modules/payment';
import { useStripeProduct } from '~modules/product';

import { MainPanel } from './MainPanel';
import { SidePanel } from './SidePanel';

import themedStyles from './styles';

export const CreditCardPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const isMobile = useBrowserType() === 'mobile';
  const { product, productError, retryProduct, isLoadingProduct } = useStripeProduct();
  const priceOutput = useCalculatePrice(product);

  if (isLoadingProduct) {
    return <ActivityIndicator />;
  }

  if (productError || !product) {
    return <ErrorMessage error={productError} onTryAgain={() => retryProduct()} />;
  }

  if (priceOutput.priceError) {
    return <ErrorMessage error={priceOutput.priceError} onTryAgain={() => priceOutput.retryPrice()} />;
  }

  return (
    <ContainerWithSidebar
      id="creditCard"
      title={
        isMobile ? (
          <Text size="xl" style={styles.mobileTitle}>
            {t('creditCard.title')}
          </Text>
        ) : (
          t('creditCard.title')
        )
      }
      mobileOrderReversed
      content={<MainPanel product={product} priceOutput={priceOutput} />}
      sidebarContent={
        <SidePanel
          priceOutput={priceOutput}
          product={product}
          mobileRender={(content, sidebarIsLoading, isError, totalPrice) => (
            <Accordion
              style={styles.accordion}
              title={(icon, isOpen) => (
                <View style={styles.accordionToggle}>
                  <Text size="xl">
                    {t(isOpen ? `creditCard.promotion.hideSummary` : `creditCard.promotion.showSummary`)}
                  </Text>
                  {icon}
                  <View style={styles.accordionPrice}>
                    {sidebarIsLoading && <ActivityIndicator size={sizes.SPACING_M} lineWidth={sizes.SPACING_XXS} />}
                    {isError && <MaterialIcon source="error" color="error" size={sizes.SPACING_L} />}
                    <Text size="xl" fontStyle="bold">
                      {formatPriceInCents(totalPrice)}
                    </Text>
                  </View>
                </View>
              )}
            >
              {content}
            </Accordion>
          )}
        />
      }
    />
  );
};
