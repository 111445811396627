import { StyleSheet } from 'react-native';

import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const schedulingThemedStyles = styleSheetFactory(theme => ({
  container: {
    justifyContent: 'flex-start',
    minHeight: '100%',
  },

  containerMobile: {
    backgroundColor: theme.background,
  },

  calendarContainer: {
    backgroundColor: theme.lightSecondaryBackground,
    top: 0,
    position: 'sticky' as any,
    zIndex: 1,
  },
  calendarContainerMobile: {
    backgroundColor: theme.background,
  },

  schedulingContentDesktop: {
    ...getShadow(),
    backgroundColor: theme.background,
    width: 640,
    maxWidth: '100%',
    marginHorizontal: 'auto',
  },

  schedulingContentHeaderDesktop: {
    borderStartStartRadius: sizes.SPACING_S,
    borderStartEndRadius: sizes.SPACING_S,
    marginTop: sizes.SPACING_XL,
    // Prevent shadow showing on bottom of sticky element
    clipPath: 'inset(-10px -10px 0px -10px)',
  },

  schedulingContentBodyDesktop: {
    // Prevent a small gap in the shadow on the left and right side, where it meets the sticky section
    paddingTop: 20,
    marginTop: -20,

    overflow: 'hidden',
    marginHorizontal: 'auto',
  },

  availabilitySection: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.borderLight,
  },

  waitlistBanner: {
    backgroundColor: theme.background,
    paddingHorizontal: sizes.SPACING_M,
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: theme.borderLight,
    gap: 12,
  },

  waitlistBannerText: {
    color: theme.darkText,
    textAlign: 'center',
  },

  infoIcon: {
    paddingHorizontal: sizes.SPACING_S,
  },

  errorDisabled: {
    opacity: 0.4,
  },
}));

export const waitlistFooterThemedStyles = styleSheetFactory(theme => ({
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 8,
    rowGap: 16,
    backgroundColor: theme.lightSecondaryBackground,
    borderTopColor: theme.borderLight,
    borderTopWidth: StyleSheet.hairlineWidth,

    paddingTop: 16,
    width: 640,
    maxWidth: '100%',
    paddingBottom: 60,
    marginHorizontal: 'auto',
  },

  containerMobile: {
    flexGrow: 1,
    overflow: 'hidden',
  },

  title: {
    textAlign: 'center',
    color: theme.darkText,
  },

  button: {
    paddingHorizontal: 30,
  },
}));

export const calendarThemedStyles = styleSheetFactory(theme => ({
  calendarButton: {
    marginHorizontal: 'auto',
    marginVertical: sizes.SPACING_M,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.secondaryTextDark,
    borderRadius: sizes.SPACING_L,
    paddingVertical: sizes.SPACING_S,
    paddingEnd: sizes.SPACING_S,
    width: 200,
  },

  calendarButtonText: {
    color: theme.darkText,
    marginStart: sizes.SPACING_M,
  },

  popover: {
    ...getShadow(true),
    borderRadius: sizes.SPACING_M,
    backgroundColor: theme.background,
  },

  bottomSheetContent: {
    flex: 1,
    backgroundColor: theme.background,
    marginTop: sizes.SPACING_L,
    borderTopStartRadius: sizes.SPACING_M,
    borderTopEndRadius: sizes.SPACING_M,
  },

  close: {
    alignSelf: 'flex-end',
    margin: sizes.SPACING_S,
  },
}));
