import React from 'react';

import { Form } from '@almond/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { emptyAtom } from '~modules/state';

import type { FormProps, FormValues } from '@almond/ui';
import type { RecoilState } from 'recoil';
import type { ObjectSchema } from 'yup';

export type JotaiFormProps = Pick<FormProps, 'formReturn'> &
  React.PropsWithChildren<{
    atom?: RecoilState<any>;
    onSubmit?: (values: FormValues) => void;
    isLoading?: boolean;
    isDataLoading?: boolean;
    validationSchema?: ObjectSchema<Record<string, any>>;
  }>;

/**
 * This component renders the react-hook-form provider, and onSubmit persists the form values to
 * the passed `atom. This was originall written to connect to Recoil. However, in ENG-4662 we're planning on
 * replacing Recoil with Jotai, so this component is named `JotaiForm` instead of `RecoilForm`
 * for future accuracy.
 */
export const JotaiForm: React.FC<JotaiFormProps> = props => {
  const atom = props.atom || emptyAtom;
  const state = useRecoilValue(atom);
  const { onSubmit } = props;

  const handleSubmit = useRecoilCallback(
    callbackInterface => async (values: FormValues) => {
      const snapshotState = await callbackInterface.snapshot.getPromise(atom);

      callbackInterface.set(atom, (prevValue: any) => ({ ...prevValue, ...values }));
      onSubmit?.({ ...snapshotState, ...values });
    },
    [onSubmit, atom]
  );

  return (
    <Form
      name="Main form"
      onSubmit={handleSubmit}
      defaultValues={state}
      isLoading={props.isLoading}
      isDisabled={props.isLoading || props.isDataLoading}
      formReturn={props.formReturn}
      shouldUnregister
      resolver={props.validationSchema ? yupResolver(props.validationSchema) : undefined}
    >
      {props.children}
    </Form>
  );
};
