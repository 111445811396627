import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';
import { useSetRecoilState } from 'recoil';

import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { FAMILY_HISTORY_PAGE_NAME, HistoryState } from '~types';

export const FamilyHistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const setHistoryState = useSetRecoilState(historyAtom);
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const { dispatch } = useRouteNavigation<typeof FAMILY_HISTORY_PAGE_NAME>();

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState> | null) => {
      if (!formValues) {
        setHistoryState(prevState => ({ ...prevState, familyHistory: undefined }));
      }

      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'family_history', 'familyHistory', t('familyHistory.skipTitle'));
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, setHistoryState, saveHistory, t, dispatch]
  );

  return (
    <JotaiForm onSubmit={handleSubmit} atom={historyAtom} isLoading={isLoading}>
      <Container
        id="familyHistory"
        title={t('familyHistory.title')}
        footer={
          <FormFooter
            submitButtonTitle={t('continue')}
            secondaryButtonTitle={t('familyHistory.skipTitle')}
            onSecondaryPress={() => handleSubmit(null)}
            requiredFields={['familyHistory']}
          />
        }
      >
        <ConnectedTextInput
          name="familyHistory"
          multiline
          placeholder={t('familyHistory.placeholder')}
          testID="FamilyHistory"
        />
      </Container>
    </JotaiForm>
  );
};
