import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  modal: {
    height: 440,
    width: 640,
  },

  modalContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: sizes.SPACING_S,
  },

  bottomText: {
    alignSelf: 'center',
    textAlign: 'center',
    color: theme.secondaryTextDark,
  },
}));
