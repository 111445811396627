import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useTheme } from '@almond/ui';

import { useWaitlistModalState } from '~modules/state';

import { schedulingThemedStyles } from './styles';

export const WaitlistBanner = () => {
  const [state, dispatch] = useWaitlistModalState();

  const [styles] = useTheme(schedulingThemedStyles);
  const { t } = useTranslation();

  return (
    <View style={styles.waitlistBanner}>
      <Text style={styles.waitlistBannerText} fontStyle="medium">
        {state.isComplete ? t('scheduling.waitlistComplete') : t('scheduling.waitlistTitle')}
      </Text>
      {!state.isComplete && (
        <Button
          type="secondary"
          size="xxs"
          onPress={() => dispatch({ type: 'open' })}
          fixedWidth={false}
          fontStyle="book"
        >
          {t('scheduling.waitlistButton')}
        </Button>
      )}
    </View>
  );
};
