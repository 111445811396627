import { useCallback, useEffect, useState } from 'react';

import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useSetRecoilState } from 'recoil';

import { useAuthState } from '~modules/auth';
import { useRedirectBackToArtemis } from '~modules/integration';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom } from '~modules/state';

import type { VisitReasonOut } from '@almond/api-types';
import type { VISIT_REASONS_PAGE_NAME } from '~types';

export const useSubmitVisitReason = (visitReasons?: readonly VisitReasonOut[]) => {
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const { dispatch } = useRouteNavigation<typeof VISIT_REASONS_PAGE_NAME>();
  const setAppointmentParamsState = useSetRecoilState(appointmentParamsAtom);
  const { redirectBackToArtemis } = useRedirectBackToArtemis();
  const [redirectBackToArtemisOnFocus, setRedirectBackToArtemisOnFocus] = useState(false);
  const authState = useAuthState();

  // When navigating to an Acuity link, the user will complete the appointment
  // reservation and close the tab. When the tab is closed, we want to bring
  // the user back to Artemis. We can't redirect the background tab to Artemis
  // upon opening the Acuity link, because the browser will focus the original
  // tab (presumably to prevent a tabnabbing attack). So instead, we wait for
  // the user to come back to the original tab, and then redirect them to Artemis.
  useEffect(() => {
    if (!redirectBackToArtemisOnFocus) {
      return;
    }

    const onVisibilityChange = () => {
      if (!document.hidden && authState === 'authenticated') {
        redirectBackToArtemis();
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [redirectBackToArtemisOnFocus, redirectBackToArtemis, authState]);

  return useCallback(
    async (item: string) => {
      const acuityBookingUrl = visitReasons?.find(vr => vr.code === item)?.acuityBookingUrl;

      if (acuityBookingUrl) {
        const url = new URL(acuityBookingUrl);

        if (url.hostname.endsWith('almondobgyn.com') || authState === 'anonymous') {
          // If the link is to our own site, open in the same tab
          window.open(acuityBookingUrl, '_self');
        } else {
          // Otherwise the link is to Acuity - open in new tab
          window.open(acuityBookingUrl, '_blank', 'noopener');

          setRedirectBackToArtemisOnFocus(true);
        }

        return;
      }

      setAppointmentParamsState(prevState => ({
        ...prevState,
        visitReason: item,
      }));

      trackAnalyticsEvent('visit_reasons', { visitReasons: item });

      await dispatch('submit', undefined, { visit_reason: item });
    },
    [authState, dispatch, setAppointmentParamsState, trackAnalyticsEvent, visitReasons]
  );
};
