import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    backgroundColor: theme.lightSecondaryBackground,
    width: 600,
  },
  contentContainer: {
    paddingTop: 24,
  },

  title: {
    color: theme.darkText,
    textAlign: 'center',
  },
  subtitle: {
    color: theme.darkText,
    textAlign: 'center',
  },
  formContainer: {
    gap: 16,
  },
  headerContainer: {
    marginHorizontal: 'auto',
    width: 450,
    maxWidth: '100%',
    gap: 10,
  },
  bodyContainer: {
    marginHorizontal: 'auto',
    width: 340,
    maxWidth: '100%',
  },
  submitButtonContainer: {
    marginHorizontal: 'auto',
    marginTop: 30,
    gap: 15,
  },
  errorSubmitContainer: {
    gap: 5,
  },
  submitButton: {
    marginHorizontal: 'auto',
    maxWidth: '100%',
  },
  multilineField: {
    maxHeight: 130,
    minHeight: 130,
  },
  availabilityTextLabel: {
    marginVertical: 5,
  },
  completeContainer: {
    width: 260,
    marginHorizontal: 'auto',
    marginVertical: 50,
  },
}));
