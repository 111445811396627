import React, { useRef } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import {
  ActivityIndicator,
  ScrollView,
  SENTRY_UNMASK_TEXT,
  Text,
  Title,
  useAdaptive,
  useBrowserTypeMap,
  useScrollToEnd,
  useShowScrollDownMessage,
  useTheme,
} from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { NavigationBar } from '~modules/integration';
import { useNavigationBar } from '~modules/patient/hooks';
import { patientAtom } from '~modules/state';
import { Background, ContactUs } from '~modules/ui';

import CenterContainer from './CenterContainer';
import { SizesMap } from './config';

import themedStyles from './styles';

import type { Size } from './config';
import type { ParseKeys } from '@almond/localization';
import type { NavigationBarProps } from '~modules/integration';
import type { BackgroundProps } from '~modules/ui';
import type { RefObject } from 'react';
import type { ScrollView as NativeScrollView, StyleProp, ViewStyle } from 'react-native';

export type ContainerProps = Pick<BackgroundProps, 'accessibilityLabel' | 'noWave'> &
  Omit<NavigationBarProps, 'isAdminHost' | 'isLoading' | 'onBack'> &
  React.PropsWithChildren<{
    id: `${ParseKeys}${string}`;
    title?: React.ReactNode;
    isLoading?: boolean;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    size?: Size;
    scrollToBottomTrigger?: any;
    scrollViewRef?: RefObject<NativeScrollView>;
    statusBar?: React.ReactNode;
    contentContainerStyle?: StyleProp<ViewStyle>;
    backgroundDecoration?: React.ReactNode;

    // Only used for Recommendation page
    navigationBarProps?: NavigationBarProps;
    backgroundProps?: BackgroundProps;
    stickyFooterAtAllWidths?: boolean;
  }>;

export const Container: React.FC<ContainerProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const titleStyle = useAdaptive(styles.titleDesktop, styles.titleMobile);
  const showScrollDownMessage = useShowScrollDownMessage();
  const size = props.size || 'L';
  const { isMobile, isDesktop } = useBrowserTypeMap();
  const { isLoading: isLoadingNavigationBar, bannerText } = useNavigationBar();
  const { navigationBarProps = {}, backgroundProps = {} } = props;
  const { isNewMember } = useRecoilValue(patientAtom);
  const stickyFooterContent = isDesktop || props.stickyFooterAtAllWidths;

  let scrollViewRef = useRef<NativeScrollView>(null);

  if (props.scrollViewRef) {
    scrollViewRef = props.scrollViewRef;
  }

  useScrollToEnd(scrollViewRef, props.scrollToBottomTrigger);

  const footerContent = (
    <View style={[styles.footer, isMobile && styles.footerContentMobile]}>
      {props.footer}
      {isNewMember && <ContactUs style={styles.contactUs} />}
    </View>
  );

  // Only show footer if it has content
  const footer = (showScrollDownMessage || stickyFooterContent) && (
    <View
      style={[
        styles.horizontalPadding,
        styles.footer,
        styles.stickyFooter,
        isDesktop && styles.stickyFooterBackground,
        isMobile && styles.stickyFooterBackgroundMobile,
      ]}
    >
      {showScrollDownMessage && <Text size="xs">{t('scrollDown')}</Text>}
      {stickyFooterContent && footerContent}
    </View>
  );

  return (
    <Background
      accessibilityLabel={props.accessibilityLabel}
      testID={props.id}
      noWave={props.noWave}
      {...backgroundProps}
    >
      {props.statusBar}
      <NavigationBar
        isLoading={isLoadingNavigationBar}
        bannerText={bannerText}
        shouldHideCloseButton={props.shouldHideCloseButton}
        {...navigationBarProps}
      />
      {props.backgroundDecoration}
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={[styles.scrollContainer, styles.horizontalPadding]}
        listenToNativeEvents
        testID="ContainerScrollView"
      >
        <CenterContainer>
          {props.header}
          {!!props.title && (
            <View style={titleStyle} role="heading" aria-level={1}>
              {typeof props.title === 'string' ? (
                <Title textAlign="center" testID={`${props.id}:${props.title}`} style={SENTRY_UNMASK_TEXT}>
                  {props.title}
                </Title>
              ) : (
                props.title
              )}
            </View>
          )}

          {props.isLoading ? (
            <ActivityIndicator testID="FormActivityIndicator" />
          ) : (
            <View style={[styles.contentContainer, { width: SizesMap[size] }, props.contentContainerStyle]}>
              {props.children}
            </View>
          )}
        </CenterContainer>
        {!stickyFooterContent && footerContent}
        {footer}
      </ScrollView>
    </Background>
  );
};
