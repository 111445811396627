import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Link, List, Text, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { useRouteNavigation } from '~modules/routing';
import { creditCardAtom, patientAtom } from '~modules/state';

import { unstable_styles as cssStyles } from './Content.module.css';

import themedStyles from './styles';

import type { useCalculatePrice } from '~modules/payment';
import type { PRICING_EXPLANATION_PAGE_NAME } from '~types';

export type MembershipProps = {
  onSubmitPress: () => void;
  isLoading: boolean;
  variant: 'virtualMembership' | 'gynecologyMembership';
  priceOutput: ReturnType<typeof useCalculatePrice>;
};

export const Membership: React.FC<MembershipProps> = props => {
  const { onSubmitPress, isLoading, variant, priceOutput } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { dispatch } = useRouteNavigation<typeof PRICING_EXPLANATION_PAGE_NAME>();
  const { isNewMember } = useRecoilValue(patientAtom);

  const creditCardState = useRecoilValue(creditCardAtom);
  const isFirstMonthFree = creditCardState.promotionCode?.code?.toLowerCase() === 'firstmonthfree';

  const defaultPrice = formatPriceInCents(priceOutput.fullPrice, true);
  const discountedPrice = formatPriceInCents(priceOutput.discountPrice, true);
  const isPriceDiscounted = priceOutput.fullPrice !== priceOutput.discountPrice;
  const isPriceDiscountedNextYear = priceOutput.discountPrice === priceOutput.renewalPrice;

  return (
    <View style={styles.container}>
      <View style={styles.banner}>
        <View style={styles.annualContainer}>
          <Text style={styles.annual} fontStyle="bold" size="xs" role="heading" aria-level={2}>
            {t('pricingExplanation.content.annual')}
          </Text>
        </View>
        <View
          style={styles.priceContainer}
          // eslint-disable-next-line max-len
          aria-label={`${(isPriceDiscounted ? `Old price ${defaultPrice} per year, new price ` : '') + discountedPrice} ${isPriceDiscountedNextYear ? 'per year' : 'for the first year'}`}
        >
          {isPriceDiscounted && (
            <Text fontStyle="medium" size="xxxl" style={styles.oldPrice} aria-hidden>
              {defaultPrice}
            </Text>
          )}
          <Text fontStyle="medium" size="xxxl" aria-hidden>
            {discountedPrice}
          </Text>
          <Text
            style={[styles.perYear, !isPriceDiscountedNextYear && styles.firstYear]}
            size="xxxl"
            fontStyle="medium"
            aria-hidden
          >
            {t(`pricingExplanation.content.${isPriceDiscountedNextYear ? 'perYear' : 'firstYear'}`)}
          </Text>
        </View>
        {creditCardState.promotionCode?.code === 'firstmonthfree' && (
          <Text style={[styles.limitedPromo, cssStyles.textBalance]} size="m" fontStyle="bold">
            {t('pricingExplanation.limitedPromo')}
          </Text>
        )}
        <Text size="m" fontStyle="bold" style={styles.sectionTitle}>
          {t(`pricingExplanation.${variant}.subscriptionItemsTitle`)}
        </Text>
        <List.UnorderedList style={styles.sectionDescription}>
          {t(`pricingExplanation.${variant}.subscriptionItems`, { returnObjects: true }).map((item, index) => (
            <List.ListItem key={index}>
              <Text size="m">{item}</Text>
            </List.ListItem>
          ))}
        </List.UnorderedList>
        <Button
          type="primary"
          style={styles.button}
          onPress={onSubmitPress}
          isLoading={isLoading}
          isDisabled={isLoading}
          size="s"
          testID="Submit"
        >
          {t('continue')}
        </Button>
        <Text size="xs" fontStyle="bookItalic" style={[styles.disclaimer, cssStyles.textBalance]}>
          {t(`pricingExplanation.content.${isFirstMonthFree ? 'disclaimerFirstMonthFree' : 'disclaimer'}`)}
        </Text>
      </View>
      {isNewMember && (
        <Link
          url="#"
          onPress={async e => {
            e.preventDefault();
            await dispatch('learnMore');
          }}
          size="m"
          style={styles.returningMember}
        >
          {t('pricingExplanation.learnMore')}
        </Link>
      )}
    </View>
  );
};
