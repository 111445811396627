import React, { useCallback, useState } from 'react';

import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useTranslation } from '@almond/localization';
import {
  ConnectedCheckbox,
  ConnectedDateInput,
  ConnectedPhoneNumberInput,
  ConnectedTextInput,
  SmsLabel,
  Text,
  useTheme,
} from '@almond/ui';
import { useFocusEffect, useGlobalSearchParams, useRouter } from 'expo-router';

import { HttpRequestError } from '~modules/errors';
import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { demographicAtom } from '~modules/state';

import { useCreateProfile, useRetrieveByEmail, useRetrieveByPhone } from '../../hooks';
import { formatPhoneNumber } from '../../services';
import FormError from './FormError';
import { validationSchema } from './validations';

import themedStyles from './styles';

import type { FormValues } from '@almond/ui';
import type { DEMOGRAPHIC_PAGE_NAME, DemographicFormValues } from '~types';

export const DemographicPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  const { dispatch } = useRouteNavigation<typeof DEMOGRAPHIC_PAGE_NAME>();
  const { doAsync, isLoading } = useAsync();
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const [error, setError] = useState<HttpRequestError>();
  const createProfile = useCreateProfile();
  const retrieveByPhone = useRetrieveByPhone();
  const retrieveByEmail = useRetrieveByEmail();
  const [patientUuid, setPatientUuid] = useState<string | null>(null);
  const router = useRouter();
  const searchParams = useGlobalSearchParams();

  const handleError = useCallback((errorResponse: any) => {
    if (!errorResponse || !(errorResponse instanceof HttpRequestError)) {
      return;
    }

    if (errorResponse.errorCode === 'display_to_user') {
      setError(errorResponse);

      return;
    }

    const err = new HttpRequestError(
      errorResponse.status,
      {},
      // eslint-disable-next-line max-len
      `There was an error submitting your information. Please review your data and try again, or call us at 424-237-8406 and we'll help you out by phone!`
    );

    setError(err);
  }, []);

  const handleSubmit = useCallback(
    (values: FormValues<DemographicFormValues>) => {
      const toCall = async (): Promise<void> => {
        setError(undefined);
        const formattedPhoneNumber = formatPhoneNumber(values.phone);
        const profileData = { ...values, phone: formattedPhoneNumber };

        // If a user enters the same first and preferred name, clear the preferred name
        // We fall back on first name when preferred name is empty
        if (profileData.firstName === profileData.preferredFirstName) {
          profileData.preferredFirstName = '';
        }

        try {
          await createProfile(profileData);

          // Track event after createProfile() is successful because
          // if it's a returning patient it will fail and we don't want to track it
          trackAnalyticsEvent('profile_created');
          await dispatch('submit');
        } catch (errorResponse: any) {
          if (errorResponse?.errorCode !== 'account_already_exists') {
            handleError(errorResponse);

            return;
          }

          try {
            setPatientUuid(null);
            const [emailResult, phoneResult] = await Promise.all([
              retrieveByEmail(values.email),
              retrieveByPhone(formattedPhoneNumber),
            ]);

            const foundPatientuuid = emailResult?.uuid || phoneResult?.uuid;

            if (foundPatientuuid) {
              setPatientUuid(foundPatientuuid);
            }

            setError(errorResponse);
          } catch (e) {
            setError(errorResponse);
          }
        }
      };

      doAsync(toCall);
    },
    [doAsync, createProfile, trackAnalyticsEvent, dispatch, handleError, retrieveByEmail, retrieveByPhone]
  );

  // Resetting all the session specific params on this page.
  // These params should be reset if we refreshed the current page during the pre-booking flow.
  useFocusEffect(
    useCallback(() => {
      const updatedSearchParams = { ...searchParams };

      // If we set some params as "undefined", then expo-router will cast them to strings.
      // We need to delete them from the search params using the "delete" operator.
      // We clear only the params that are specified in the array below.
      ['profile_uuid', 'patient_uuid', 'appointment_uuid', 'booking_uuid', 'email'].forEach(param => {
        if (updatedSearchParams[param]) {
          delete updatedSearchParams[param];
        }
      });

      router.setParams(updatedSearchParams);
    }, [router, searchParams])
  );

  return (
    <JotaiForm atom={demographicAtom} onSubmit={handleSubmit} isLoading={isLoading} validationSchema={validationSchema}>
      <Container
        id="demographic"
        title={t('demographic.title')}
        size="M"
        footer={
          <FormFooter
            submitButtonTitle={t('continue')}
            requiredFields={['firstName', 'lastName', 'birthday', 'email', 'phone']}
            formError={<FormError error={error} patientUuid={patientUuid} />}
          />
        }
      >
        <ConnectedTextInput
          name="firstName"
          label={`${t('demographic.firstName')}*`}
          textContentType="givenName"
          testID="FirstName"
        />
        <ConnectedTextInput
          name="preferredFirstName"
          label={t('demographic.preferredFirstName')}
          textContentType="nickname"
          testID="PreferredFirstName"
        />
        <ConnectedTextInput
          name="lastName"
          label={`${t('demographic.lastName')}*`}
          textContentType="familyName"
          testID="LastName"
        />
        <ConnectedDateInput
          name="birthday"
          label={`${t('demographic.birthday')}*`}
          testID="BirthDate"
          format="MM/DD/YY"
        />
        <ConnectedTextInput
          name="email"
          label={`${t('demographic.email')}*`}
          inputMode="email"
          textContentType="emailAddress"
          testID="Email"
        />
        <ConnectedPhoneNumberInput
          name="phone"
          label={`${t('demographic.cell')}*`}
          textContentType="telephoneNumber"
          testID="Cell"
        />
        <ConnectedCheckbox name="isOptedIntoSms" label={() => <SmsLabel includeNewLines />} testID="IsOptedIntoSms" />
        <Text style={styles.requiredNotice}>{`* ${t('demographic.required')}`}</Text>
      </Container>
    </JotaiForm>
  );
};
