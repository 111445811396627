body {
  /*
     * This prevents the overall page from overscrolling, or from pulling to refresh.
     * This makes the fixed header feel more like a real fixed header
     */
  height: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
}

ul {
  margin: 0;
}

/* Use :where to give this zero specificity, so it's easy to override the outline color */
:where(:focus-visible, .almond-text-input-wrapper-pressable:focus):not(iframe) {
  outline: 2px solid var(--color-primary, currentColor);
}
