import React from 'react';

import { useTranslation } from '@almond/localization';
import { Text } from '@almond/ui';
import { formatPriceInCents, parseStrikethrough } from '@almond/utils';

import { ModalButton } from '../ModalButton';

import type { BaseButtonProps } from '@almond/ui';

export type JoinAlmondButtonProps = Omit<BaseButtonProps, 'children'> & {
  totalPrice: number;
  discountedPrice: number;
};

export const JoinAlmondButton = (props: JoinAlmondButtonProps) => {
  const { style, totalPrice, discountedPrice, ...restProps } = props;
  const { t } = useTranslation();
  const buttonText = (() => {
    const pricePerYear = `${t('firstMonthFreeModal.perYear', { price: formatPriceInCents(discountedPrice, true) })}`;

    return parseStrikethrough(
      `${t('firstMonthFreeModal.joinAlmondFor')} ~${formatPriceInCents(totalPrice, true)}~ ${pricePerYear}`
    );
  })();

  return (
    <>
      <ModalButton testID="FirstMonthFreeModal-Submit" {...restProps}>
        {buttonText}
      </ModalButton>
      <Text size="m">
        {t('firstMonthFreeModal.equivalentTo', { price: formatPriceInCents(discountedPrice / 12, true) })}
      </Text>
    </>
  );
};
