import React from 'react';
import { View } from 'react-native';

import { SubmitButton, useTheme } from '@almond/ui';

import ContextButton from '../ContextButton';
import Error from '../Error';

import themedStyles from './styles';

import type { ContextButtonProps } from '../ContextButton';
import type { ButtonType, FormContext, RequiredFieldsMode, SubmitButtonProps } from '@almond/ui';
import type { PropsWithChildren } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

export type FormFooterProps = PropsWithChildren<{
  submitButtonProps?: Omit<SubmitButtonProps, 'testID' | 'type' | 'requiredFieldsMode' | 'requiredFields'>;
  onSecondaryPress?: (context: FormContext) => void;
  secondaryButtonProps?: Omit<ContextButtonProps, 'onPress' | 'mode' | 'type' | 'testID'>;
  submitButtonTitle?: string;
  submitButtonType?: ButtonType;
  submitButtonTestID?: string;
  secondaryButtonTitle?: string;
  buttonsStyle?: StyleProp<ViewStyle>;
  formError?: React.ReactNode;
  requiredFields?: string[];
  requiredFieldsMode?: RequiredFieldsMode;
}>;

export const FormFooter = (props: FormFooterProps) => {
  const { onSecondaryPress, submitButtonProps = {}, secondaryButtonProps = {} } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={[styles.buttons, props.buttonsStyle]}>
      {!!props.submitButtonTitle && (
        <SubmitButton
          testID={props.submitButtonTestID || 'Submit'}
          requiredFields={props.requiredFields}
          requiredFieldsMode={props.requiredFieldsMode}
          type={props.submitButtonType}
          {...submitButtonProps}
        >
          {props.submitButtonTitle}
        </SubmitButton>
      )}

      {!!props.secondaryButtonTitle && (
        <ContextButton type="secondary" onPress={onSecondaryPress} testID="Secondary" {...secondaryButtonProps}>
          {props.secondaryButtonTitle}
        </ContextButton>
      )}

      {typeof props.formError === 'string' || typeof props.formError === 'undefined' ? (
        <Error formError={props.formError} />
      ) : (
        props.formError
      )}

      {props.children}
    </View>
  );
};
