import React, { useState } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import {
  ActivityIndicator,
  ConnectedPhoneNumberInput,
  ConnectedSimpleSelectInput,
  ConnectedTextInput,
  Form,
  SubmitButton,
  Text,
  useTheme,
} from '@almond/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilValue } from 'recoil';

import { dailyAvailabilityApi } from '~modules/api';
import { Error } from '~modules/forms';
import { isVirtualMembershipProduct, useStripeMembership } from '~modules/product';
import { useAsync } from '~modules/promises';
import { patientAtom } from '~modules/state';

import { useVisitReasons } from '../../hooks';
import { validationSchema } from './validations';

import { themedStyles } from './styles';

import type { WaitlistEntryCreateIn, WaitlistLocationEnum } from '@almond/api-types';
import type { SelectOption } from '@almond/ui';
import type { AppError } from '~modules/errors';

type WaitlistModalFormProps = {
  onComplete: () => void;
};

const locationOptions: SelectOption<WaitlistLocationEnum>[] = [
  { label: 'In-Person', value: 'office' },
  { label: 'Video', value: 'video' },
  { label: 'In-person or video', value: 'office_or_video' },
];

export const WaitlistModalForm = (props: WaitlistModalFormProps) => {
  const { onComplete } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const { visitReason } = useVisitReasons();
  const membership = useStripeMembership();
  const { patientUuid } = useRecoilValue(patientAtom);

  const { doAsync, isLoading } = useAsync();
  const [error, setError] = useState<AppError | null>(null);

  const locationDisabled = isVirtualMembershipProduct(membership);
  const location: WaitlistLocationEnum = (() => {
    const isTelehealth = visitReason?.visitOutcome?.isTelehealth;

    if (locationDisabled) {
      return 'video';
    }

    if (typeof isTelehealth === 'boolean') {
      return isTelehealth ? 'video' : 'office';
    }

    return 'office_or_video';
  })();

  if (!visitReason) {
    return <ActivityIndicator />;
  }

  const defaultValues: WaitlistEntryCreateIn = {
    appointmentReason: visitReason.title,
    availabilityText: '',
    location,
  };

  const requiredFields = ['appointmentReason', 'availabilityText'];

  if (!patientUuid) {
    requiredFields.push('fullName', 'phoneNumber');
  }

  const handleSubmit = (data: any) => {
    doAsync(
      async () => {
        await dailyAvailabilityApi.createWaitlistEntry({
          ...data,
          patientUuid,
          // Display the visit reason title to user, but submit the code to the API
          appointmentReason: visitReason.code,
        });

        onComplete();
      },
      { setError }
    );
  };

  return (
    <View style={styles.formContainer}>
      <View style={styles.headerContainer}>
        <Text size="xxl" fontStyle="medium" style={styles.title}>
          {t('scheduling.waitlistModal.title')}
        </Text>
        <Text size="m" style={styles.subtitle}>
          {t('scheduling.waitlistModal.subtitle')}
        </Text>
      </View>
      <View style={styles.bodyContainer}>
        <Form
          name="Waitlist form"
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          shouldUnregister
          isLoading={isLoading}
          isDisabled={isLoading}
          resolver={patientUuid ? undefined : yupResolver(validationSchema)}
        >
          <ConnectedTextInput
            name="appointmentReason"
            label={t('scheduling.waitlistModal.fields.appointmentReason')}
            isDisabled
          />
          <ConnectedSimpleSelectInput
            autoFocus={!locationDisabled}
            name="location"
            options={locationOptions}
            label={`${t('scheduling.waitlistModal.fields.location')}*`}
            isDisabled={locationDisabled}
          />

          {!patientUuid && (
            <>
              <ConnectedTextInput
                autoFocus={locationDisabled}
                name="fullName"
                label={`${t('scheduling.waitlistModal.fields.fullName')}*`}
                testID="WaitlistModal-FullName"
              />
              <ConnectedPhoneNumberInput
                name="phoneNumber"
                label={`${t('scheduling.waitlistModal.fields.phoneNumber')}*`}
                testID="WaitlistModal-PhoneNumber"
              />
            </>
          )}
          <Text size="m" style={styles.availabilityTextLabel}>
            {`${t('scheduling.waitlistModal.fields.availabilityText')}*`}
          </Text>
          <ConnectedTextInput
            autoFocus={locationDisabled && !!patientUuid}
            name="availabilityText"
            multiline
            style={styles.multilineField}
            placeholder={t('scheduling.waitlistModal.availabilityTextHelper')}
            testID="WaitlistModal-Availability"
          />
          <View style={[styles.submitButtonContainer, styles.errorSubmitContainer]}>
            <Error backendError={error?.message} />
            <SubmitButton
              requiredFields={requiredFields}
              requiredFieldsMode="all"
              style={styles.submitButton}
              testID="WaitlistModal-Submit"
            >
              {t('continue')}
            </SubmitButton>
          </View>
        </Form>
      </View>
    </View>
  );
};
