import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, List, Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

export type BaselineProps = {
  onSubmitPress: () => void;
  isLoading: boolean;
};

export const Baseline: React.FC<BaselineProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container}>
      <View style={styles.wideBanner}>
        <Text size="xl" fontStyle="bold" style={styles.sectionTitle}>
          {t('pricingExplanation.baseline.subscriptionItemsTitle')}
        </Text>
        <List.UnorderedList style={styles.sectionDescription}>
          {t('pricingExplanation.baseline.subscriptionItems', { returnObjects: true }).map((item, index) => (
            <List.ListItem key={index} style={styles.baselineSectionItem}>
              <Text size="l">
                {Array.isArray(item)
                  ? item.map((itemPart, itemPartIndex) => {
                      if (itemPartIndex === 1) {
                        return (
                          <Text size="l" key={itemPartIndex} fontStyle="bold">
                            {itemPart}
                          </Text>
                        );
                      }

                      return itemPart;
                    })
                  : item}
              </Text>
            </List.ListItem>
          ))}
        </List.UnorderedList>
        <Button
          type="primary"
          style={styles.fixedWidthButton}
          onPress={props.onSubmitPress}
          isLoading={props.isLoading}
          isDisabled={props.isLoading}
          fixedWidth={true}
          size="m"
          testID="Submit"
        >
          {t('pricingExplanation.baseline.submit')}
        </Button>
      </View>
    </View>
  );
};
