import { accountSetupAtom, addressAtom } from '../accountSetup';
import { appointmentAvailabilityMetadataAtom, appointmentParamsAtom, waitlistAtom } from '../appointment';
import { routingAlreadyInitializedAtom } from '../auth';
import { errorsAtom } from '../errors';
import { historyAtom } from '../history';
import { integrationAtom } from '../integration';
import { patientAtom, patientProgressAtom } from '../patient';
import { creditCardAtom, demographicAtom, insuranceAtom, profileAtom, profileProgressAtom } from '../profile';
import { questionnaireAtom } from '../questionnaire';

export const atomsToSerialize = [
  accountSetupAtom,
  addressAtom,
  appointmentAvailabilityMetadataAtom,
  appointmentParamsAtom,
  waitlistAtom,
  errorsAtom,
  historyAtom,
  patientAtom,
  patientProgressAtom,
  profileAtom,
  demographicAtom,
  creditCardAtom,
  insuranceAtom,
  profileProgressAtom,
  questionnaireAtom,
  routingAlreadyInitializedAtom,
  integrationAtom,
];
