import { useMemo } from 'react';

import { useGlobalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';
import useSWRImmutable from 'swr/immutable';
import { z } from 'zod';

import { PRODUCTS_PATH, productsApi } from '~modules/api';
import { patientProgressAtom } from '~modules/state';

import { isBaselineProduct } from '../services';

import type { ProductKeyEnum, ProductOut } from '@almond/api-types';

// Handle invalid product key values
const membershipKeySchema = z.enum(['gynecology_membership', 'virtual_membership']).optional();

export const useStripeProduct = () => {
  // Since this hook is used in Stripe Elements component, must be global not local search params
  const searchParams = useGlobalSearchParams();
  const { subscription } = useRecoilValue(patientProgressAtom);

  // If user has an existing subscription (active or expired), use that as the product.
  // Otherwise, use the product from the searchParams.
  // In either case, if baseline is the product in the URL, use that.
  // This prevents a user from specifying a different membership type in the URL than they signed up for.
  const productKey: ProductKeyEnum = isBaselineProduct(searchParams.product)
    ? searchParams.product
    : (membershipKeySchema.safeParse(subscription?.membershipType ?? searchParams.product).data ??
      'gynecology_membership');

  const { data, isLoading, error, mutate, ...restResponse } = useSWRImmutable(PRODUCTS_PATH, productsApi.getProducts);

  const product: ProductOut | undefined = useMemo(
    () => data?.products.find(p => p.almondProductKey === productKey),
    [data, productKey]
  );

  return {
    product,
    isLoadingProduct: isLoading || !productKey,
    productError: error,
    retryProduct: mutate,
    ...restResponse,
  };
};
