import { useEvent } from '@almond/utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { productsApi } from '~modules/api';
import { useStripeProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { appointmentParamsAtom, creditCardAtom, patientProgressAtom } from '~modules/state';

import type { PromotionCode } from '~types';

export const useCheckCode = () => {
  const { doAsync, isLoading } = useAsync();
  const setCreditCardState = useSetRecoilState(creditCardAtom);
  const { subscriptionActive } = useRecoilValue(patientProgressAtom);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const { product } = useStripeProduct();
  const isResubscribe = !subscriptionActive && !isNewMemberRemote;

  const checkCode = useEvent((code: string, discountCode?: [string, PromotionCode | undefined]) => {
    return doAsync(async (): Promise<PromotionCode> => {
      if (isResubscribe && code === 'firstmonthfree') {
        return { code: 'firstmonthfree', state: 'not-found' };
      }

      const result = await productsApi.checkPromotionCode(code, product?.almondProductKey);

      if (result.state === 'success') {
        if (result.promotionCodeType === 'stripe_discount') {
          setCreditCardState(prevState => ({ ...prevState, promotionCode: result }));
        } else {
          setCreditCardState(prevState => ({ ...prevState, friendReferralCode: result }));
        }
      }

      if (result.state === 'not-found' || result.state === 'not-applicable') {
        if (discountCode) {
          setCreditCardState(prevState => ({ ...prevState, [discountCode[0]]: discountCode[1] }));
        }
      }

      return result;
    });
  });

  return { checkCode, isCheckingCode: isLoading };
};
