import React from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';
import { formatPriceInCents, parseStrikethrough } from '@almond/utils';

import { Stars } from '~assets';

import themedStyles from './styles';

import type { useCalculatePrice } from '~modules/payment';

export type PricingExplanationTitleProps = {
  priceOutput: ReturnType<typeof useCalculatePrice>;
  variant: 'baseline' | 'virtualMembership' | 'gynecologyMembership';
};

export const PricingExplanationTitle = (props: PricingExplanationTitleProps) => {
  const { priceOutput, variant } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  const title = (() => {
    if (variant === 'baseline') {
      const { fullPrice, discountPrice } = priceOutput;
      const price =
        fullPrice === discountPrice
          ? formatPriceInCents(discountPrice, true)
          : parseStrikethrough(`~${formatPriceInCents(fullPrice, true)}~ ${formatPriceInCents(discountPrice, true)}`);

      return (
        <>
          {t('pricingExplanation.baseline.title')}
          {price}
        </>
      );
    }

    return t(`pricingExplanation.${variant}.title`);
  })();

  return (
    <Text
      testID={`pricingExplanation.${variant}`}
      role="heading"
      aria-level={1}
      fontStyle="medium"
      size="xxxxl"
      style={styles.centerText}
    >
      <Stars style={styles.stars} />
      {title}
    </Text>
  );
};
