/* eslint-disable max-statements */
import React, { useEffect, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useToastNotification } from '@almond/ui';
import { useToggle } from '@almond/utils';
import { useGlobalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { FEEDBACK_PATH, feedbackApi } from '~modules/api';
import { useAsync } from '~modules/promises';
import { patientAtom, profileProgressAtom, useWaitlistModalState } from '~modules/state';

import { useRedirectBackToArtemis } from '../../hooks';
import { logError } from '../../logger';
import { ConfirmationModal } from '../ConfirmationModal';
import { ContactUsModal } from '../ContactUsModal';
import { FeedbackModal } from '../FeedbackModal';
import { FirstMonthFreeModal } from '../FirstMonthFreeModal';
import { IdleModal } from '../IdleModal';
import { useRunOnUserIdle } from './useRunOnUserIdle';
import { useShouldDisplayIdleModal } from './useShouldDisplayIdleModal';

import type { Key } from '../IdleModal';

type ActiveModal = {
  id: 'idle' | 'contactUs' | 'feedback' | 'confirmation' | 'firstMonthFree' | 'waitlist';
  onBeforeSubmit?: () => Promise<string>;
};

export const IdleModalWrapper = () => {
  const [isVisible, toggleIsVisible] = useToggle(false);
  const shouldDisplayIdleModal = useShouldDisplayIdleModal();
  const [activeModal, setActiveModal] = useState<ActiveModal>({ id: 'idle' });
  const [feedbackUuid, setFeedbackUuid] = useState<string>();
  const { doAsync, isLoading } = useAsync({});
  const { backToArtemisLinkProps } = useRedirectBackToArtemis();
  const { href } = backToArtemisLinkProps('/messages');
  const { patientUuid, isNewMember } = useRecoilValue(patientAtom);
  const { t } = useTranslation();
  const { isPricingPageViewed } = useRecoilValue(profileProgressAtom);
  const { showToast } = useToastNotification();
  const [waitlistState, waitlistDispatch] = useWaitlistModalState();
  const searchParams = useGlobalSearchParams();
  const waitlistIsAllowed = Boolean(searchParams.visit_reason && !searchParams.booking_uuid);

  useRunOnUserIdle(toggleIsVisible, shouldDisplayIdleModal && !isVisible);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    waitlistDispatch({ type: activeModal.id === 'waitlist' ? 'open' : 'close' });
  }, [activeModal.id, isVisible, waitlistDispatch]);

  // Once waitlist modal is closed, close the AbandonModal
  useEffect(() => {
    if (activeModal.id === 'waitlist' && !waitlistState.isVisible) {
      toggleIsVisible();
      setActiveModal({ id: 'idle' });
    }
  }, [activeModal.id, waitlistState.isVisible, toggleIsVisible, setActiveModal]);

  if (!shouldDisplayIdleModal || !isVisible) {
    return null;
  }

  const handlePress = (key: Key, text: string) => {
    const toCall = async () => {
      const patientResponse: { text: string; patientUuid?: string } = { text };

      if (!isNewMember) {
        patientResponse.patientUuid = patientUuid;
      }

      try {
        const response = await feedbackApi.createFeedback()({
          eventType: 'idle',
          response: patientResponse,
        });

        setFeedbackUuid(response.uuid);

        if (key === 'somethingElse' && !isNewMember) {
          setActiveModal({ id: 'feedback' });
        } else if (key === 'contactUs') {
          if (isNewMember) {
            setActiveModal({ id: 'contactUs' });
          } else {
            toggleIsVisible();
            window.open(href, '_blank', 'noopener');
          }
        } else if (key === 'notSure' && !isPricingPageViewed) {
          setActiveModal({ id: 'firstMonthFree' });
        } else if (key === 'noDays' && waitlistIsAllowed) {
          setActiveModal({ id: 'waitlist' });
        } else {
          setActiveModal({ id: 'confirmation' });
        }
      } catch (err) {
        if (key === 'somethingElse') {
          setActiveModal({
            id: 'feedback',
            onBeforeSubmit: async () => {
              const response = await feedbackApi.createFeedback()({
                eventType: 'idle',
                response: patientResponse,
              });

              return response.uuid;
            },
          });
        } else {
          // Don't block user from completing their action if the API request fails, but log
          // a useful error to Sentry
          logError(`Call to idle ${FEEDBACK_PATH} failed`, `Payload: ${JSON.stringify(patientResponse)}`);
        }
      }
    };

    doAsync(toCall);
  };

  const onRequestClose = () => {
    toggleIsVisible();
    setActiveModal({ id: 'idle' });
  };

  const feedbackModalUuid = feedbackUuid || activeModal.onBeforeSubmit;

  return (
    <>
      <IdleModal
        isVisible={activeModal.id === 'idle'}
        isLoading={isLoading}
        onRequestClose={onRequestClose}
        onPress={handlePress}
      />
      <ContactUsModal
        isVisible={activeModal.id === 'contactUs'}
        onRequestClose={onRequestClose}
        onBack={() => setActiveModal({ id: 'idle' })}
        onPress={() => setActiveModal({ id: 'confirmation' })}
      />
      {feedbackModalUuid && (
        <FeedbackModal
          isVisible={activeModal.id === 'feedback'}
          onRequestClose={onRequestClose}
          feedbackUuid={feedbackModalUuid}
          onSubmit={() => setActiveModal({ id: 'confirmation' })}
          eventType="idle"
          onBack={() => setActiveModal({ id: 'idle' })}
        />
      )}
      <FirstMonthFreeModal
        isVisible={activeModal.id === 'firstMonthFree'}
        onRequestClose={onRequestClose}
        onBack={() => setActiveModal({ id: 'idle' })}
        onSubmit={() => {
          showToast({ text: t('firstMonthFreeModal.toastText'), type: 'info', duration: 3200 });
          onRequestClose();
        }}
      />
      <ConfirmationModal
        isVisible={activeModal.id === 'confirmation'}
        onRequestClose={onRequestClose}
        onPress={onRequestClose}
        submitTitle={t('done')}
      />
    </>
  );
};
