import React from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput, Text, useTheme } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useSaveQuestionnaire } from '~modules/questionnaire';
import { useRouteNavigation } from '~modules/routing';
import { patientAtom, questionnaireAtom } from '~modules/state';

import { Footer } from './Footer';

import { themedStyles } from './styles';

import type { FormValues } from '@almond/ui';
import type { QuestionnaireState, TELL_US_MORE_PAGE_NAME } from '~types';

export const TellUsMorePage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const { dispatch } = useRouteNavigation<typeof TELL_US_MORE_PAGE_NAME>();
  const saveQuestionnaire = useSaveQuestionnaire();
  const [styles] = useTheme(themedStyles);
  const { isNewMember } = useRecoilValue(patientAtom);

  const handleSubmit = (values: FormValues<QuestionnaireState> | null) => {
    const toCall = async (): Promise<void> => {
      if (!isNewMember) {
        await saveQuestionnaire(values, 'visit_questions', 'visitQuestions');
      }

      await dispatch('submit');
    };

    doAsync(toCall);
  };

  return (
    <JotaiForm atom={questionnaireAtom} isLoading={isLoading} onSubmit={handleSubmit}>
      <Container
        id="tellUsMore"
        title={t('tellUsMore.title')}
        footer={<FormFooter submitButtonTitle={t('continue')}>{!isNewMember && <Footer />}</FormFooter>}
      >
        <Text style={styles.header} size="m" fontStyle="bold">
          {t('tellUsMore.header')}
        </Text>
        <ConnectedTextInput
          name="visitQuestions"
          multiline
          placeholder={t('tellUsMore.placeholder')}
          testID="TellUsMore"
        />
      </Container>
    </JotaiForm>
  );
};
