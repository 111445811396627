import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { Divider, Text, TextInput, useTheme } from '@almond/ui';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

import env from '~env';

import { DEFAULT_GEOLOCATION, GOOGLE_MAPS_PROXY_PATH } from '../../config';
import { locationParsers } from '../../services';

import themedStyles from './styles';

import type { TextInputProps } from '@almond/ui';
import type { LocationAttributes } from '~types';
import type { TextInput as NativeTextInput } from 'react-native';
import type {
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocompleteProps,
  GooglePlacesAutocompleteRef,
  RequestUrl,
} from 'react-native-google-places-autocomplete';

export type PlacesInputProps = Omit<
  GooglePlacesAutocompleteProps,
  'GooglePlacesDetailsQuery' | 'query' | 'requestUrl' | 'onPress' | 'fetchDetails' | 'placeholder'
> &
  Pick<
    TextInputProps,
    'testID' | 'accessibilityLabel' | 'autoCapitalize' | 'textContentType' | 'left' | 'label' | 'isDisabled'
  > & {
    onSelect: (location: Partial<LocationAttributes> | undefined) => void;
    types?: string;
    autocompleteRef?: React.RefObject<GooglePlacesAutocompleteRef>;
    textInputRef?: React.RefObject<NativeTextInput>;
  };

const PLACES_PROXY_URL = `${env.API_BASE_URL}${GOOGLE_MAPS_PROXY_PATH}`;
const requestUrl: RequestUrl = { useOnPlatform: 'web', url: PLACES_PROXY_URL };

const PlacesInput: React.FC<PlacesInputProps> = props => {
  const {
    testID,
    accessibilityLabel,
    onSelect,
    types,
    autocompleteRef,
    textInputRef,
    autoCapitalize,
    textContentType,
    left,
    label,
    isDisabled,
    ...restProps
  } = props;
  const [styles] = useTheme(themedStyles);
  const query = useMemo(
    () => ({
      key: env.PLACES_API_KEY,
      language: 'en',
      components: 'country:us',
      location: DEFAULT_GEOLOCATION,
      radius: 500,
      rankby: 'distance',
      types,
    }),
    [types]
  );

  const handlePlaceSelect = useCallback(
    (_: GooglePlaceData, details: GooglePlaceDetail | null) => {
      if (!details) return;

      const locationAttributes = locationParsers.parseToLocationAttributes(details);

      onSelect(locationAttributes);
    },
    [onSelect]
  );

  return (
    <GooglePlacesAutocomplete
      {...restProps}
      placeholder=""
      ref={autocompleteRef}
      styles={styles}
      query={query}
      requestUrl={requestUrl}
      onPress={handlePlaceSelect}
      enablePoweredByContainer
      fetchDetails
      textInputProps={{
        InputComp: TextInput,
        testID,
        ref: textInputRef,
        left,
        label,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'aria-label': accessibilityLabel,
        style: styles.textInput,
        containerStyle: [styles.textInputComponentContainer, props.styles],
        helperTextContainerStyle: styles.helperTextContainerStyle,
        autoCapitalize,
        textContentType,
        onChangeText: text => {
          onSelect(text ? { addressLine1: text } : undefined);
        },
        autoComplete: 'none',
        isDisabled,
      }}
      renderRow={data => (
        <Text testID="PlacesInputResult" style={styles.description}>
          {data.description}
        </Text>
      )}
      renderResults={(results, isListViewOpen) => {
        return (
          results &&
          isListViewOpen && (
            <View style={styles.results}>
              <Divider style={styles.divider} />
              {results}
            </View>
          )
        );
      }}
    />
  );
};

export default PlacesInput;
