import React from 'react';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

export type PricingExplanationSubtitleProps = {
  variant: 'baseline' | 'virtualMembership' | 'gynecologyMembership';
};

export const PricingExplanationSubtitle = (props: PricingExplanationSubtitleProps) => {
  const { variant } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  return (
    <Text size="l" style={styles.subtitle}>
      {t(`pricingExplanation.${variant}.subtitle`)}
    </Text>
  );
};
