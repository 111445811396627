import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import { productsApi } from '~modules/api';
import { creditCardAtom, patientProgressAtom } from '~modules/state';

import { calculateDiscount, isPromotionCodeRepeating } from '../services';

import type { ProductOut } from '@almond/api-types';
import type { PromotionCodeResult } from '~types';

export const useCalculatePrice = (product: Pick<ProductOut, 'price'> | undefined) => {
  const creditCardState = useRecoilValue(creditCardAtom);
  const patientProgressState = useRecoilValue(patientProgressAtom);

  const isCodeLoading =
    creditCardState.promotionCode?.state === 'init' || creditCardState.friendReferralCode?.state === 'init';

  let promotionCode: PromotionCodeResult | undefined;
  let friendReferralCode: PromotionCodeResult | undefined;

  if (creditCardState.promotionCode?.state === 'success') {
    promotionCode = creditCardState.promotionCode;
  }

  if (creditCardState.friendReferralCode?.state === 'success') {
    friendReferralCode = creditCardState.friendReferralCode;
  }

  const {
    data: price,
    error: priceError,
    isLoading: isLoadingPrice,
    mutate: retryPrice,
  } = useSWR(
    isCodeLoading || !product?.price.id
      ? null
      : [product.price.id, promotionCode?.id, friendReferralCode?.id, promotionCode?.code === 'firstmonthfree'],
    ([priceId, promotionCodeId, friendReferralCodeId, firstMonthFree]) => {
      return productsApi.calculatePrice(priceId)({
        firstMonthFree,
        promotionCode: firstMonthFree ? undefined : promotionCodeId,
        friendReferralCode: friendReferralCodeId,
      });
    },
    { revalidateOnFocus: false }
  );

  if (isCodeLoading || patientProgressState.subscriptionActive) {
    return {
      fullPrice: product?.price.amount ?? 0,
      discountPrice: product?.price.amount ?? 0,
      renewalPrice: product?.price.amount ?? 0,
      firstMonthFreeDiscount: 0,
      priceError: null,
      isLoadingPrice: patientProgressState.subscriptionActive ? false : isLoadingPrice,
      retryPrice,
    };
  }

  if (product && price) {
    const { fullPrice = 0, discountPrice = 0 } = price;
    const repeatingCodes = [promotionCode, friendReferralCode].filter(code => isPromotionCodeRepeating(code));
    const renewalPrice = repeatingCodes.reduce((acc, cur) => {
      if (!cur) {
        return acc;
      }

      return acc - calculateDiscount(product, cur);
    }, fullPrice);
    const firstMonthFreeDiscount =
      fullPrice -
      discountPrice -
      calculateDiscount(product, promotionCode) -
      calculateDiscount(product, friendReferralCode);

    return {
      fullPrice,
      discountPrice,
      renewalPrice,
      firstMonthFreeDiscount,
      priceError,
      isLoadingPrice,
      retryPrice,
    };
  }

  return {
    fullPrice: product?.price.amount ?? 0,
    discountPrice: product?.price.amount ?? 0,
    renewalPrice: product?.price.amount ?? 0,
    firstMonthFreeDiscount: 0,
    priceError,
    isLoadingPrice,
    retryPrice,
  };
};
