import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { useTheme } from '@almond/ui';

import { FormFooter, JotaiForm, PharmacyInput } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { accountSetupAtom } from '~modules/state';

import { useCreatePharmacy } from '../../hooks';

import themedStyles from './styles';

import type { FormValues } from '@almond/ui';
import type { PHARMACY_PAGE_NAME, PharmacyFormValues } from '~types';

export const PharmacyPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const createPharmacy = useCreatePharmacy();
  const [styles] = useTheme(themedStyles);
  const { dispatch } = useRouteNavigation<typeof PHARMACY_PAGE_NAME>();

  const handleSubmit = useCallback(
    (values: FormValues<PharmacyFormValues>) => {
      const toCall = async (): Promise<void> => {
        await createPharmacy(values);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, createPharmacy, dispatch]
  );

  return (
    <JotaiForm atom={accountSetupAtom} onSubmit={handleSubmit} isLoading={isLoading}>
      <Container
        id="pharmacy"
        title={t('pharmacy.title')}
        footer={<FormFooter submitButtonTitle={t('continue')} requiredFields={['pharmacy', 'pharmacy.placesId']} />}
      >
        <PharmacyInput name="pharmacy" testID="Pharmacy" styles={styles.placesInput} />
      </Container>
    </JotaiForm>
  );
};
