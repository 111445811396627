import type { ProductOut } from '@almond/api-types';
import type { PromotionCode } from '~types';

export const calculateDiscount = (product: Pick<ProductOut, 'price'>, promotionCode: PromotionCode | undefined) => {
  let amountOff = 0;

  if (!promotionCode || promotionCode.state !== 'success') {
    return amountOff;
  }

  if (promotionCode.amountOff) {
    amountOff = promotionCode.amountOff;
  }

  if (promotionCode.percentOff) {
    amountOff = product.price.amount * (Number(promotionCode.percentOff) / 100);
  }

  return amountOff;
};

export const isPromotionCodeRepeating = (promotionCode?: PromotionCode) => {
  if (!promotionCode || promotionCode.state !== 'success') {
    return false;
  }

  const isForever = promotionCode?.duration === 'forever';
  const isRepeatingAfterOneYear =
    promotionCode?.duration === 'repeating' && promotionCode?.durationInMonths && promotionCode.durationInMonths > 12;

  return isForever || !!isRepeatingAfterOneYear;
};
