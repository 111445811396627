import { isValidPhoneNumber } from 'libphonenumber-js';

import { Yup } from '~modules/forms';

export const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().test('phoneNumber', 'Please enter a valid 10 digit phone number', v => {
    if (!v) {
      return false;
    }

    return isValidPhoneNumber(v, 'US');
  }),
});
