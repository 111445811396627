import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { Label, Text, useTheme } from '@almond/ui';
import { formatPriceInCents, parseStrikethrough, useEvent } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { PAYMENT_FAILED_ERROR_STATUS_CODE, PaymentRequiredError } from '~modules/errors';
import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useCalculatePrice, useCreateSubscription } from '~modules/payment';
import { isVirtualMembershipProduct, useStripeMembership, useStripeProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom, patientProgressAtom, userAtom } from '~modules/state';
import { MessageContainer } from '~modules/ui';

import { useCreateAppointment, useCreateAppointmentBooking, useVisitReasons } from '../../hooks';
import { logError } from '../../logger';

import themedStyles from './styles';

import type { AppointmentText } from '@almond/api-types';
import type { BOOK_APPOINTMENT_PAGE_NAME } from '~types';

type StringOrJsx<T> = T extends Record<infer K, string> ? Record<K, React.ReactNode> : never;

// eslint-disable-next-line max-statements
export const BookAppointmentPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const [isCreatingAppointment, setIsCreatingAppointment] = useState(true);
  const { doAsync: doAsyncBooking, isLoading: isBooking } = useAsync();
  const { doAsync: doAsyncAppointment } = useAsync({ setIsLoading: setIsCreatingAppointment });
  const createAppointmentBooking = useCreateAppointmentBooking();
  const { product } = useStripeProduct();
  const createAppointment = useCreateAppointment(product);
  const { discountPrice, fullPrice, isLoadingPrice } = useCalculatePrice(product);
  const createSubscription = useCreateSubscription(discountPrice);
  const userState = useRecoilValue(userAtom);
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);
  const patientProgressState = useRecoilValue(patientProgressAtom);
  const isVirtualMembership = isVirtualMembershipProduct(useStripeMembership());
  const { isAdmin } = userState;
  const { dispatch } = useRouteNavigation<typeof BOOK_APPOINTMENT_PAGE_NAME>();
  const [appointmentDetails, setAppointmentDetails] = useState<StringOrJsx<AppointmentText>>();
  const { visitReason } = useVisitReasons();

  const runOnPageLoad = useEvent(async () => {
    const response = await createAppointment();

    if (patientProgressState.subscriptionActive || appointmentParamsState.isNewMemberRemote) {
      // Returning member already has a subscription, OR it's a brand new member starting their
      // subscription for the first time. Either way, not a *re* subscribe.
      //
      // Brand new member may have used a promo code, so call parseStrikethrough(). Returning
      // members won't, but their text will just not contain strikethrough annotation, so
      // parseStrikethrough() is a noop (until/unless we add a strikethrough for something)
      setAppointmentDetails({ ...response, paymentText: parseStrikethrough(response.paymentText) });
    } else if (product) {
      let price: React.ReactNode;

      if (discountPrice === fullPrice) {
        price = formatPriceInCents(discountPrice);
      } else {
        price = parseStrikethrough(`~${formatPriceInCents(fullPrice)}~ ${formatPriceInCents(discountPrice)}`);
      }

      setAppointmentDetails({
        ...response,
        paymentText: (
          <>
            {`${product.name} Renewal: `}
            {price}
            {'\n'}
            {parseStrikethrough(response.paymentText)}
          </>
        ),
      });
    }
  });

  // Run on page load. Only runs once, because runOnPageLoad is wrapped
  // in useEvent(), and doAsync should always be the same reference
  useEffect(() => {
    doAsyncAppointment(runOnPageLoad);
  }, [doAsyncAppointment, runOnPageLoad]);

  const handleSubmit = useCallback(async () => {
    const toCall = async () => {
      if (!visitReason) {
        // Should never happen, since the submit button is disabled if there's no visit reason
        return;
      }

      // If the patient has a subscription, we can create the booking and immediately exit
      if (patientProgressState.subscriptionActive) {
        await createAppointmentBooking(visitReason);

        return;
      }

      let subscription: string | null;

      try {
        // If the patient is a new member, we need to create a subscription.
        subscription = await createSubscription();
      } catch (error: any) {
        // This error is caught specifically on this page because
        // admins are allowed to book an appointment without a subscription.
        // Catching the error here allows us to route to the CC page if the
        // added CC was invalid or continue if the logged in user is an admin.
        if (error instanceof PaymentRequiredError && error.status === PAYMENT_FAILED_ERROR_STATUS_CODE) {
          return dispatch('cardRequired', error.message);
        }

        throw error;
      }

      if (!subscription && !isAdmin) {
        logError(
          new Error(`Subscription wasn't created for patient user.`),
          JSON.stringify({ userId: userState.user?.authId })
        );

        return;
      }

      await createAppointmentBooking(visitReason);
    };

    doAsyncBooking(toCall);
  }, [
    doAsyncBooking,
    patientProgressState.subscriptionActive,
    isAdmin,
    createAppointmentBooking,
    visitReason,
    createSubscription,
    dispatch,
    userState.user?.authId,
  ]);

  const isDataLoading = isCreatingAppointment || isLoadingPrice || !visitReason;

  return (
    <JotaiForm onSubmit={handleSubmit} isLoading={isBooking} isDataLoading={isDataLoading}>
      <Container
        id="bookAppointment"
        title={t(`bookAppointment.${appointmentParamsState.isTelehealth ? 'video' : 'office'}Title`)}
        isLoading={isDataLoading}
        stickyFooterAtAllWidths
        footer={
          <FormFooter
            submitButtonTitle={t(
              `bookAppointment.submit${appointmentParamsState.isTelehealth ? 'Video' : 'Office'}Title`
            )}
            submitButtonTestID="ConfirmBooking"
            submitButtonType="accent"
          />
        }
      >
        {appointmentDetails && appointmentParamsState.isNewMemberRemote && (
          <Text size="m" fontStyle="bold" style={styles.newMemberDisclaimer}>
            {isVirtualMembership
              ? t('bookAppointment.newMemberVirtualDisclaimer')
              : t('bookAppointment.newMemberDisclaimer')}
          </Text>
        )}
        {appointmentDetails && (
          <MessageContainer>
            <Label title={t('bookAppointment.appointmentTime')}>{appointmentDetails.timeText}</Label>
            <Label title={t('bookAppointment.provider')}>{appointmentDetails.providerText}</Label>
            <Label title={t('bookAppointment.location')}>{appointmentDetails.locationText}</Label>
            <Label title={t('visitReason')}>{appointmentDetails.reasonsText}</Label>
            <Label title={t('bookAppointment.payment')} details={appointmentDetails.extendedPaymentText} isLast>
              {appointmentDetails.paymentText}
            </Label>
            <Text size="s" style={styles.bottom}>
              {t('bookAppointment.bottom')}
            </Text>
          </MessageContainer>
        )}
      </Container>
    </JotaiForm>
  );
};
