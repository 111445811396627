import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput } from '@almond/ui';

import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HistoryState, OCCUPATION_PAGE_NAME } from '~types';

export const OccupationPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const { dispatch } = useRouteNavigation<typeof OCCUPATION_PAGE_NAME>();

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState>) => {
      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'occupation', 'occupation');
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, saveHistory, dispatch]
  );

  return (
    <JotaiForm onSubmit={handleSubmit} atom={historyAtom} isLoading={isLoading}>
      <Container
        id="occupation"
        title={t('occupation.title')}
        footer={<FormFooter submitButtonTitle={t('continue')} requiredFields={['occupation']} />}
      >
        <ConnectedTextInput name="occupation" multiline placeholder={t('occupation.placeholder')} testID="Occupation" />
      </Container>
    </JotaiForm>
  );
};
