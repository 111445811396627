import React, { useRef, useState } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useLocalSearchParams, useNavigation } from 'expo-router';
import { useRecoilState, useRecoilValue } from 'recoil';

import env from '~env';
import { errorsAtom, userAtom } from '~modules/state';
import { Error } from '~modules/ui';

import { useSkipCreditCard, useSubmitCreditCard } from '../../hooks';

import themedStyles from './styles';

import type { ProductOut } from '@almond/api-types';
import type { useCalculatePrice } from '~modules/payment';
import type { CreditCardParams } from '~types';

type MainPanelProps = {
  product: ProductOut;
  priceOutput: ReturnType<typeof useCalculatePrice>;
};

export const MainPanel: React.FC<MainPanelProps> = props => {
  const { product, priceOutput } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const searchParams = useLocalSearchParams<CreditCardParams>();
  const { isMobile } = useBrowserTypeMap();
  const [errorsState, setErrorsState] = useRecoilState(errorsAtom);
  const [initialError, setInitialError] = useState(searchParams.error);
  const error = initialError || errorsState.error;
  const { isAdmin } = useRecoilValue(userAtom);

  const resetErrors = () => {
    setInitialError(undefined);
    setErrorsState({ error: undefined });
  };

  const { submitCreditCard, isLoading: isSubmittingCreditCard } = useSubmitCreditCard({
    product,
    resetErrors,
    discountPrice: priceOutput.discountPrice,
  });
  const { skipCreditCard, isLoading: isSkippingCreditCard } = useSkipCreditCard({ product, resetErrors });
  const [isReady, setIsReady] = useState(false);
  const isLoading = isSubmittingCreditCard || isSkippingCreditCard;
  const isDisabled = isLoading || !isReady;
  const formRef = useRef<HTMLFormElement>(null);
  const navigation = useNavigation();
  const applePay = (() => {
    const isRecurring = product?.price.type === 'recurring';

    if (!isRecurring || !priceOutput.renewalPrice) {
      return;
    }

    const recurringPaymentRequest = {
      paymentDescription: product.name,
      managementURL: env.APPLE_PAY_MANAGEMENT_URL,
      regularBilling: {
        amount: priceOutput.renewalPrice,
        label: product.name,
        recurringPaymentIntervalUnit: 'year',
      },
    } as const;

    return { recurringPaymentRequest };
  })();

  return (
    <View style={[styles.formWrapper, isMobile && styles.formWrapperMobile]}>
      <form
        onSubmit={async event => {
          event.preventDefault();

          await submitCreditCard();
        }}
        ref={formRef}
      >
        <View style={styles.formWrapperInner}>
          <View>
            {navigation.isFocused() && (
              <PaymentElement
                options={{
                  readOnly: isDisabled,
                  defaultValues: {
                    billingDetails: {
                      address: {
                        country: 'US',
                      },
                    },
                  },
                  applePay,
                }}
                onReady={() => setIsReady(true)}
              />
            )}

            {isReady && (
              <Text size="s" style={styles.termsText}>
                {t('creditCard.termsText')}
              </Text>
            )}
          </View>

          {!isLoading && error && <Error>{error}</Error>}

          <Button
            testID="Submit"
            style={styles.button}
            onPress={() => {
              // Previously called formRef.current.requestSubmit(), but not supported in Safari 15.
              // This does the same thing, with Safari 15 support
              const formSubmitEvent = new SubmitEvent('submit', { cancelable: true, bubbles: true });

              formRef?.current?.dispatchEvent(formSubmitEvent);
            }}
            isLoading={isLoading}
            isDisabled={isDisabled}
          >
            {t('continue')}
          </Button>
          {isAdmin && (
            <Button
              mode="text"
              type="primary"
              onPress={skipCreditCard}
              testID="Skip"
              style={styles.button}
              isLoading={isLoading}
              isDisabled={isDisabled}
            >
              {t('creditCard.skipTitle')}
            </Button>
          )}
        </View>
      </form>
    </View>
  );
};
