import React, { useCallback, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useSetRecoilState } from 'recoil';

import { FormFooter, JotaiForm, SelectInputGroup } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { historyAtom } from '~modules/state';

import { PREGNANCIES_OPTIONS } from '../../config';
import { useSaveHistory } from '../../hooks';

import type { FormValues } from '@almond/ui';
import type { HistoryState, PREGNANCIES_PAGE_NAME } from '~types';

export const PregnanciesPage: React.FC = () => {
  const { t } = useTranslation();
  const setHistoryState = useSetRecoilState(historyAtom);
  const { doAsync, isLoading } = useAsync();
  const saveHistory = useSaveHistory();
  const [pregnanciesLength, setPregnanciesLength] = useState<number>();
  const { dispatch } = useRouteNavigation<typeof PREGNANCIES_PAGE_NAME>();

  const handleLengthChange = useCallback((length: number) => {
    setPregnanciesLength(length);
  }, []);

  const handleSubmit = useCallback(
    (formValues: FormValues<HistoryState> | null) => {
      if (!formValues) {
        setHistoryState(prevState => ({ ...prevState, pregnancies: undefined }));
      }

      const toCall = async (): Promise<void> => {
        await saveHistory(formValues, 'pregnancies', 'pregnancies', []);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [doAsync, setHistoryState, saveHistory, dispatch]
  );

  return (
    <JotaiForm onSubmit={handleSubmit} atom={historyAtom} isLoading={isLoading}>
      <Container
        id="pregnancies"
        title={t('pregnancies.title')}
        scrollToBottomTrigger={pregnanciesLength}
        footer={
          <FormFooter
            submitButtonTitle={t('continue')}
            secondaryButtonTitle={t('pregnancies.skipTitle')}
            onSecondaryPress={() => handleSubmit(null)}
            requiredFields={['pregnancies']}
          />
        }
      >
        <SelectInputGroup
          name="pregnancies"
          options={PREGNANCIES_OPTIONS}
          keyTitle={t('pregnancies.year')}
          valueTitle={t('pregnancies.outcome')}
          keyTestID="Year"
          valueTestID="Outcome"
          onLengthChange={handleLengthChange}
        />
      </Container>
    </JotaiForm>
  );
};
