import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { withEnforceAuthWhenNecessary } from '~modules/navigation';
import { isBaselineProduct } from '~modules/product';
import { userAtom } from '~modules/state';

export default withEnforceAuthWhenNecessary(() => {
  const { isAdmin } = useRecoilValue(userAtom);
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);

  if (isBaselineProduct(searchParams.get('product'))) {
    return <Redirect href={`/demographic?${searchParams}`} />;
  }

  if (isAdmin) {
    return <Redirect href={`/visit-categories?${searchParams}`} />;
  }

  return <Redirect href={`/visit-reasons?${searchParams}`} />;
});
