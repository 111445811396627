import { styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitle: {
    color: theme.secondaryTextLight,
    textAlign: 'center',
    top: 0,
    position: 'absolute',
  },
  contentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  image: {
    width: 196,
    height: 212,
  },
}));
