import React from 'react';

import { useTranslation } from '@almond/localization';

import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useRouteNavigation } from '~modules/routing';

import { VisitCostMessage } from '../../components';

import type { CASH_RATES_PAGE_NAME } from '~types';

export const CashRatesPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof CASH_RATES_PAGE_NAME>();

  return (
    <JotaiForm onSubmit={() => dispatch('submit')}>
      <Container id="cashRates" title={t('cashRates.title')} footer={<FormFooter submitButtonTitle={t('continue')} />}>
        <VisitCostMessage />
      </Container>
    </JotaiForm>
  );
};
