import type { QuestionnaireInWithAppointment } from '@almond/api-types';
import type { QuestionnaireState } from '~types';

export const parseToPatientQuestionnaireIn = (
  questionnaireState: QuestionnaireState,
  currentState: QuestionnaireInWithAppointment['currentState'],
  appointmentUuid?: string
): QuestionnaireInWithAppointment => {
  return {
    ...questionnaireState,
    appointmentUuid,
    currentState,
    source: 'demi_classic',
  };
};
