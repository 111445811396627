import React, { useState } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ConnectedOtpInput, ResendCodeButton, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { cypressUtilities } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { OTP_SESSIONS_PATH, profilesApi, useFocusedSWR } from '~modules/api';
import { ErrorMessage } from '~modules/errors';
import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { demographicAtom, profileAtom } from '~modules/state';
import { LocalImage } from '~modules/ui';

import { logAndCapture } from '../../logger';
import { validationSchema } from './validations';

import { themedStyles } from './styles';

import type { PHONE_VERIFICATION_PAGE_NAME, PhoneVerificationFormValues } from '~types';

const OTP_INTERVAL = 60; // 60 seconds

export const PhoneVerificationPage = () => {
  const { t } = useTranslation();
  const demographicState = useRecoilValue(demographicAtom);
  const profileState = useRecoilValue(profileAtom);
  const [styles] = useTheme(themedStyles);
  const {
    data: otpSession,
    isLoading: isLoadingOtpSession,
    error: otpSessionError,
    mutate: mutateOtpSession,
  } = useFocusedSWR(
    profileState.uuid ? OTP_SESSIONS_PATH(profileState.uuid) : null,
    async () => {
      if (!profileState.uuid) return;

      return profilesApi.createOtpSession(profileState.uuid)();
    },
    { revalidateOnFocus: false }
  );
  const [isSessionValidating, setIsSessionValidating] = useState(false);
  const { doAsync } = useAsync({ setIsLoading: setIsSessionValidating });
  const { dispatch } = useRouteNavigation<typeof PHONE_VERIFICATION_PAGE_NAME>();
  const { isMobile } = useBrowserTypeMap();

  if (otpSessionError) {
    return <ErrorMessage error={otpSessionError} onTryAgain={() => mutateOtpSession()} />;
  }

  const handleSubmit = (values: PhoneVerificationFormValues) => {
    const toCall = async () => {
      if (!profileState.uuid) {
        logAndCapture("profile uuid is not specified, so can't confirm the otp session.");

        return;
      }

      if (!otpSession) {
        logAndCapture("Otp session was not created, so can't validate it.");

        return;
      }

      await profilesApi.validateOtpSession(profileState.uuid)({
        otp: values.otp,
        sessionUuid: otpSession.sessionUuid,
      });
      await dispatch('submit');
    };

    doAsync(toCall);
  };

  return (
    <JotaiForm
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      isLoading={isSessionValidating}
      isDataLoading={isLoadingOtpSession}
    >
      <Container
        id="phoneVerification"
        title={t(`phoneVerification.${isMobile ? 'titleMobile' : 'title'}`)}
        size="L"
        isLoading={isLoadingOtpSession}
        header={isMobile && <LocalImage source="universe2" width={200} height={220} style={styles.imageMobile} />}
        footer={<FormFooter submitButtonTitle={t('phoneVerification.submitTitle')} requiredFields={['otp']} />}
      >
        <View style={styles.row}>
          <Text size="l">{t('phoneVerification.subtitle')}</Text>
          <Text fontStyle="bold" size="l">
            {demographicState.phone}
          </Text>
        </View>
        <View style={styles.inputContainer}>
          <ConnectedOtpInput name="otp" testID="VerificationCode" />
          <ResendCodeButton
            interval={cypressUtilities.isCypressRunning() ? 3 : OTP_INTERVAL}
            onPress={() => mutateOtpSession()}
          />
        </View>
      </Container>
    </JotaiForm>
  );
};
