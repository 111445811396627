import type { AppointmentAvailabilityMetadataState, AppointmentParamsState, WaitlistState } from '~types';

export const APPOINTMENT_AVAILABILITY_METADATA_STATE_KEY = 'appointmentAvailabilityMetadataState';
export const APPOINTMENT_PARAMS_STATE_KEY = 'appointmentParamsState';
export const WAITLIST_STATE_KEY = 'waitlistState';

export const DEFAULT_APPOINTMENT_AVAILABILITY_METADATA_STATE: AppointmentAvailabilityMetadataState = {};

export const DEFAULT_APPOINTMENT_PARAMS_STATE: AppointmentParamsState = {
  isNewMemberRemote: true,
};

export const DEFAULT_WAITLIST_STATE: WaitlistState = {
  isVisible: false,
  isComplete: false,
  isOnSchedulingPage: false,
};
