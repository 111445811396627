import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Text, useTheme } from '@almond/ui';

import { ErrorMessage } from '~modules/errors';
import { useCalculatePrice } from '~modules/payment';
import { useStripeProduct } from '~modules/product';

import { BaseModal, type BaseModalProps } from '../BaseModal';
import { JoinAlmondButton } from './JoinAlmondButton';

import { themedStyles } from './styles';

export type FirstMonthFreeModalProps = Omit<BaseModalProps, 'title' | 'subtitle'> & {
  onSubmit: () => void;
};

export const FirstMonthFreeModal = (props: FirstMonthFreeModalProps) => {
  const { onSubmit, ...restProps } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const { product } = useStripeProduct();
  const { fullPrice, discountPrice, isLoadingPrice, retryPrice, priceError } = useCalculatePrice(product);
  const content = (() => {
    if (isLoadingPrice) {
      return <ActivityIndicator />;
    }

    if (priceError) {
      return <ErrorMessage error={priceError} onTryAgain={retryPrice} />;
    }

    return <JoinAlmondButton totalPrice={fullPrice} discountedPrice={discountPrice} onPress={onSubmit} />;
  })();

  return (
    <BaseModal
      title={t('firstMonthFreeModal.title')}
      containerStyle={styles.modal}
      subtitle={t('firstMonthFreeModal.subtitle')}
      {...restProps}
    >
      <View style={styles.modalContent}>{content}</View>
      <Text size="m" style={styles.bottomText}>
        {t('firstMonthFreeModal.bottomText')}
      </Text>
    </BaseModal>
  );
};
