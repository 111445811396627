import React from 'react';

import { Link, Text, useTheme } from '@almond/ui';

import { MessageContainer } from '../MessageContainer';

import themeStyles from './styles';

import type { StyleProp, ViewStyle } from 'react-native';

export type InfoMessage = {
  title?: string;
  description?: React.ReactNode;
  linkTitle?: string;
  link?: string;
  style?: StyleProp<ViewStyle>;
};

export const InfoMessage: React.FC<InfoMessage> = props => {
  const [styles] = useTheme(themeStyles);

  let { description } = props;
  const { style } = props;

  if (props.description && typeof props.description === 'string') {
    description = props.description.split('\n').map((text, i) => (
      <Text key={i} size="m" role={'paragraph' as any}>
        {text}
      </Text>
    ));
  }

  return (
    <MessageContainer style={[styles.container, style]}>
      {props.title && (
        <Text fontStyle="bold" size="xxl" style={styles.title} role="heading" accessibilityLevel={2}>
          {props.title}
        </Text>
      )}
      {description}
      {props.linkTitle && props.link && (
        <Link url={props.link} style={styles.link}>
          {props.linkTitle}
        </Link>
      )}
    </MessageContainer>
  );
};
